@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

// Below global variables are used in the legacy header re-implementation

:root {
    --legacy-header-font-family: 'Arial', 'Helvetica', sans-serif;
    --legacy-header-color-light-blue: #eaf9fa;
    --legacy-header-color-loyal-blue: #007db4;
    --legacy-header-color-light-gray: #dee1ec;
    --legacy-header-color-deep-blue: #003b49;
    --legacy-header-color-loyal-red: #dd2734;
    --legacy-header-font-weight-medium-bold: 600;
    --legacy-header-font-weight-bolder: 900;
    --legacy-header-myaccount-dropdown-border-color: #dfdfdf;
}

@mixin legacy-header-container-styles {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: to-rem(1240px) !important;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

// Below are the styles for the legacy header re-implementation

.legacy-header {
    &__section {
        padding: 0 !important;

        &--primary {
            background-color: var(--sparky-theme-color-primary-foreground);

            @media screen and (max-width: $sparky-bp-lg) {
                display: none;
            }
        }
    }

    &__container {
        @include legacy-header-container-styles;
    }

    &__banner--desktop {
        @include legacy-header-container-styles;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        min-height: to-rem(150px) !important;
        padding: 0 to-rem(20px) !important;

        .header-drawer__button.sparky-c-button {
            top: 25%;
            margin: auto to-rem(10px);
            padding: 0;
            width: to-rem(25px);

            @media screen and (max-width: $sparky-bp-lg) {
                right: 0 !important;
            }

            .sparky-c-icon {
                width: to-rem(20px);
                height: to-rem(20px);
            }

            order: 1;
        }

        .sparky-c-button.sparky-c-inline-form__close-button {
            @media screen and (max-width: $sparky-bp-lg) {
                display: none;
            }
        }

        .legacy-header {
            &__logo {
                order: 3;
                display: flex;
                justify-content: center;
                animation: all 0.3s ease-in-out;

                @media screen and (max-width: $sparky-bp-sm-2) {
                    flex: 1 0 auto;
                }

                @media screen and (max-width: $sparky-bp-lg) {
                    margin: 0 to-rem(10px) to-rem(5px);
                }

                @media screen and (min-width: $sparky-bp-lg) {
                    flex: 0 0 calc(100% / 12 * 3);
                }

                .sparky-c-logo__link:focus {
                    outline: none;
                }

                svg {
                    height: to-rem(26px);

                    .legacy-header-desktop & {
                        height: to-rem(30px);
                    }
                }
            }

            &__search {
                order: 5;
                flex-basis: 100%;
                text-align: center;
                padding: 0;

                > .autocomplete {
                    z-index: inherit;
                    margin: 0;

                    @media screen and (min-width: $sparky-bp-lg) {
                        width: calc(100% - to-rem(20px)) !important;
                        left: to-rem(20px);
                    }

                    @media screen and (max-width: $sparky-bp-lg) {
                        width: 100% !important;
                        left: 0;

                        &.autocomplete--open {
                            background-color: transparent !important;
                            top: auto;
                            margin-top: -to-rem(15px);

                            .autocomplete-searchbox {
                                background-color: var(
                                    --sparky-color-neutral-white
                                );
                                box-shadow: none;
                            }
                        }
                    }

                    @media screen and (min-width: $sparky-bp-lg) {
                        width: calc(100% - 20px) !important;
                        left: to-rem(20px);
                    }

                    .sparky-c-inline-form {
                        position: relative;

                        .sparky-c-text-input:hover,
                        .sparky-c-text-input:focus,
                        .sparky-c-text-input {
                            padding: 0 to-rem(20px) 0 to-rem(10px) !important;
                            width: 100%;
                            height: to-rem(46px) !important;
                            background: var(
                                --sparky-color-neutral-white
                            ) !important;
                            border-radius: to-rem(3px);
                            border: to-rem(1px) solid
                                var(--sparky-color-neutral-400) !important;
                            outline: none !important;
                            color: var(--sparky-color-neutral-900) !important;
                            font-family: var(--legacy-header-font-family);
                            font-size: to-rem(18px);
                            line-height: to-rem(25px);
                            padding-right: to-rem(80px) !important;

                            @media screen and (min-width: $sparky-bp-sm-2) and (max-width: $sparky-bp-lg) {
                                height: to-rem(37px) !important;
                            }
                        }

                        .sparky-c-button {
                            top: 25%;
                            margin: auto to-rem(10px);
                            padding: 0;
                            width: to-rem(25px);

                            @media screen and (max-width: $sparky-bp-lg) {
                                right: 0 !important;
                            }

                            .sparky-c-icon {
                                width: to-rem(20px);
                                height: to-rem(20px);
                            }
                        }

                        .sparky-c-button.sparky-c-inline-form__close-button {
                            @media screen and (max-width: $sparky-bp-lg) {
                                display: none;
                            }
                        }
                    }

                    .autocomplete-panel {
                        border: to-rem(1px) solid #dee1ec;

                        .autocomplete-list-item__button {
                            padding: 0 to-rem(16px) !important;
                            text-align: left;

                            &:hover {
                                background-color: #ccc;
                            }

                            .autocomplete-list-item__button-content {
                                padding: 0 !important;
                            }
                        }
                    }
                }

                .sparky-c-inline-form__input,
                .sparky-c-inline-form__input:hover,
                .sparky-c-inline-form__input:focus {
                    width: 100%;
                    height: to-rem(46px) !important;
                    border: to-rem(1px) solid var(--sparky-color-neutral-400) !important;
                    outline: none !important;
                }

                @media screen and (min-width: $sparky-bp-md) and (max-width: $sparky-bp-lg) {
                    height: to-rem(72px);
                    left: to-rem(235px);
                    right: to-rem(140px);
                    top: 0;
                    display: flex;
                    align-items: center;
                }

                @media screen and (min-width: $sparky-bp-lg) {
                    flex: 0 0 calc(100% / 12 * 5);
                }
            }

            &__account {
                order: 2;

                .legacy-account {
                    display: none;
                }

                @media screen and (max-width: $sparky-bp-lg) {
                    margin: 0 to-rem(10px) 0 to-rem(25px);
                }

                @media screen and (min-width: $sparky-bp-lg) {
                    flex: 0 0 calc(100% / 12 * 3);
                }
            }

            &__cart {
                order: 4;

                @media screen and (max-width: $sparky-bp-lg) {
                    min-width: 4rem;
                    margin-left: auto;
                    margin-right: to-rem(10px);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row;
                }

                @media screen and (min-width: $sparky-bp-lg) {
                    flex: 0 0 calc(100% / 12 * 1);
                }
            }
        }

        @media screen and (min-width: ($sparky-bp-lg)) {
            display: flex;
            min-height: 0 !important;
            padding: 0 !important;
            justify-content: inherit;

            .header-drawer__button.sparky-c-button {
                display: none;
            }

            .legacy-header {
                &__logo,
                &__search,
                &__account,
                &__cart {
                    order: initial; /* Resetea el orden a su posición natural en el HTML */
                }

                &__logo {
                    flex-basis: 25%;
                }

                &__search {
                    flex-basis: 42%;

                    .autocomplete {
                        z-index: var(--sparky-z-index-top);
                    }
                }

                &__account {
                    flex-basis: 25%;

                    .legacy-account {
                        display: block;
                    }

                    .legacy-header__person {
                        display: none;
                    }
                }

                &__cart {
                    flex-basis: 8%;

                    .legacy-header__cart-pin-icon {
                        display: none;
                    }
                }
            }
        }
    }

    &__person {
        border: none;
        background-color: transparent;
    }

    .legacy-topnav__container {
        display: none;

        @media screen and (min-width: $sparky-bp-lg) {
            display: flex;

            .legacy-usablenet__link {
                display: block;
            }
        }
    }

    .legacy-usablenet__link {
        @media screen and (min-width: $sparky-bp-lg) {
            display: none;
        }
    }
}

.legacy-header__banner {
    position: relative;
    width: 100%;

    @media screen and (min-width: ($sparky-bp-lg)) {
        display: none;
    }
}

.legacy-header__banner-head {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-height: to-rem(72px);
    padding: 0 to-rem(25px);
}

.legacy-header__account a {
    color: var(--sparky-color-neutral-black);
}
