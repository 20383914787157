@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.c-promo-card {
    display: grid;
    height: 100%;
    min-height: 286px;
    border-radius: var(--sparky-border-radius-xl);
    text-decoration: none;
    overflow: hidden;
    color: inherit;

    .sparky-c-heading {
        font-size: size(5);
        line-height: size(6);
    }

    .sparky-c-text-passage {
        font-size: 18px;
        line-height: size(3);
    }

    .sparky-c-button {
        margin-top: auto;
        margin-right: auto;
    }

    .sparky-c-image {
        object-fit: cover;
        height: 100%;
    }

    .sparky-c-picture {
        grid-area: 1 / 1;
    }

    &__content {
        display: flex;
        padding: size(2);
        flex-direction: column;
        grid-area: 1 / 1;
        gap: size(2);
    }

    @media (min-width: $sparky-bp-md) {
        min-height: 297px;
    }

    @media (min-width: $sparky-bp-lg) {
        height: 392px;

        .sparky-c-heading {
            font-size: size(6);
            line-height: size(7);
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            max-height: 110px;
            overflow: hidden;
        }

        .sparky-c-text-passage {
            font-size: 20px;
            line-height: 28px;
        }

        &__content {
            padding: size(4);
        }
    }
}
