@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';

$submenu-header-background: #f2f7fc;
$submenu-space-top: 16px;

.legacy-mainmenu__primary-nav .submenu-container {
    // Reset list style
    .sparky-c-primary-nav__item {
        list-style-type: none;
    }

    // Remove button/a as we only need the list
    .sparky-c-primary-nav__link {
        display: none;
    }

    // Layout Type : Text
    .submenu__layout--text .submenu-container__list {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row wrap;
        gap: to-rem(16px);
        padding: 25px 10px 20px;

        > .sparky-c-primary-nav__item {
            padding-bottom: size(4);

            .sparky-c-link-list {
                gap: 0;

                & > li {
                    margin-bottom: size(2);

                    &:first-child {
                        margin-bottom: size(1);
                    }
                }
            }

            // 1st item in category columns
            .sparky-c-link-list__link {
                color: var(--sparky-color-brand-blue-500);
                font-size: var(--sparky-font-size-20);
                font-family: var(--legacy-header-font-family);
                line-height: to-rem(54px);
            }
        }

        .sparky-c-primary-nav__item {
            page-break-inside: avoid;
            break-inside: avoid-column;
        }
    }

    // Category type : Default / Links
    .sparky-c-text-link {
        display: block;
        color: var(--sparky-color-neutral-black);
        font-size: var(--sparky-font-size-16);
        font-family: var(--legacy-header-font-family);
        line-height: to-rem(14px);

        &:hover {
            color: var(--sparky-color-brand-blue-500);
        }
    }

    // Layout Type : Tabs
    .submenu__layout--tabs .submenu-container__list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-flow: row wrap;

        > [class^='sparky-c-tabs_'] {
            padding: 0;
            align-self: stretch;
        }

        .sparky-c-primary-nav__item {
            flex: 1;
        }
    }

    /*
     *SUBMENU HEADER STYLES
     */
    .submenu-container__list > .sparky-c-tabs__header {
        margin-right: 10px;
        padding-top: 10px;
        min-width: 140px;
        width: 16%;
        background: $submenu-header-background;

        .sparky-c-tabs__list {
            display: flex;
            flex-direction: column;
            margin-top: to-rem($submenu-space-top);
        }

        .sparky-c-tabs__link {
            background-color: transparent;
            box-shadow: none;
            font-size: 20px;
            color: var(--sparky-color-neutral-black);
            font-family: var(--legacy-header-font-family);
            text-align: left;
            text-transform: capitalize;
            text-wrap: nowrap;
            width: 100%;

            &:hover {
                color: var(--sparky-theme-color-primary-foreground);
            }
        }

        .sparky-c-tabs__item {
            margin: 0;
            width: 100%;
            height: 60px;
            background: $submenu-header-background;
            display: flex;
            align-items: center;
            line-height: to-rem(60px);

            &.sparky-is-active,
            &:hover {
                background-color: var(--sparky-color-neutral-white);

                .sparky-c-tabs__link {
                    color: var(--sparky-theme-color-primary-foreground);
                }
            }
        }
    }

    /*
     * SUBMENU BODY STYLES
     */
    .submenu-container__list > .sparky-c-tabs__body {
        flex: 1;
        height: 100%;

        > .sparky-c-tabs__panel {
            margin-top: size(1);
            margin-bottom: size(0);
            gap: size(2);
            height: 100%;

            > .submenu-container__list {
                padding: 0 to-rem(10px);
                column-gap: to-rem(15px);

                // category columns
                > .sparky-c-primary-nav__item {
                    padding-top: to-rem($submenu-space-top);
                    padding-bottom: size(1);

                    // make the header style for panel with one item
                    > .sparky-c-primary-nav__item-panel {
                        > .sparky-c-text-link {
                            color: var(--sparky-color-brand-blue-500);
                            font-size: var(--sparky-font-size-20);
                            font-family: var(--legacy-header-font-family);
                            line-height: size(3);
                            min-height: size(7.25);
                        }
                    }

                    .sparky-c-link-list {
                        // inherited gap adds too much space between 1st and 2nd item
                        // set gap to 0 and use margin starting from 2nd item instead
                        gap: 0;

                        &.is-legacy-grid {
                            display: grid;
                            column-gap: to-rem(10px);
                            grid-template-rows: to-rem(58px) repeat(7, 32px);
                            grid-auto-flow: column;
                            grid-template-areas: 'header header' 'main main';

                            .sparky-c-link-list__item {
                                grid-area: header;
                            }

                            .sparky-c-link-list__item,
                            .sparky-c-primary-nav__item {
                                min-width: 160px;
                            }
                        }

                        .sparky-c-primary-nav__item.category__item--header {
                            .sparky-c-text-link {
                                color: var(--sparky-color-brand-blue-500);
                                font-size: var(--sparky-font-size-20);
                                line-height: size(3);
                                min-height: size(7.25);
                            }
                        }
                    }

                    // 1st item in category columns
                    .sparky-c-link-list__link {
                        color: var(--sparky-color-brand-blue-500);
                        font-size: var(--sparky-font-size-20);
                        font-family: var(--legacy-header-font-family);
                        line-height: to-rem(24px);
                        min-height: to-rem(58px);
                    }

                    // 2nd or later item in category columns
                    .sparky-c-primary-nav__item {
                        page-break-inside: avoid;
                        break-inside: avoid-column;
                        margin: to-rem(8px) 0 to-rem(8px) 0;

                        &.category__item--header {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
