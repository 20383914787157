@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.promo-banner {
    margin-bottom: size(4);

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);
    }

    .sparky-c-promo-banner__body {
        .sparky-c-promo-banner__content {
            width: 100%;

            .sparky-c-promo-banner__title,
            .sparky-c-promo-banner__description {
                text-align: left;
            }
        }
    }

    &:not(.sparky-c-promo-banner--sm, .sparky-c-promo-banner--xlg)
        .sparky-c-promo-banner__content {
        .sparky-c-promo-banner__title {
            font-size: var(--sparky-font-size-20) !important;
            line-height: to-rem(28px) !important;
        }
    }

    .sparky-c-promo-banner__content {
        text-align: left;

        .sparky-c-promo-banner__button {
            height: to-rem(40px);
            border-radius: to-rem(4px);
        }
    }

    @media screen and (min-width: $sparky-bp-sm-2) and (max-width: $sparky-bp-lg) {
        .sparky-c-promo-banner__body {
            row-gap: to-rem(16px);
        }
    }

    @media screen and (min-width: $sparky-bp-sm-2) {
        .sparky-c-promo-banner__body .sparky-c-promo-banner__button {
            flex: 0 0 auto;
        }
    }

    @media screen and (min-width: $sparky-bp-lg) {
        .sparky-c-promo-banner__body {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .sparky-c-promo-banner__button {
                margin-top: 0;
                min-width: to-rem(150px);
                width: auto;

                &.sparky-c-button--sm {
                    min-width: initial;
                    padding: to-rem(9px) to-rem(12px);
                }
            }
        }
    }

    &.sparky-c-promo-banner--lg {
        @media screen and (min-width: $sparky-bp-lg) {
            .sparky-c-promo-banner__body {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .sparky-c-promo-banner__content {
                    .sparky-c-promo-banner__description {
                        .sparky-c-text-passage {
                            .sparky-c-text-passage__inner {
                                max-width: to-rem(434px);
                                max-height: to-rem(38px);
                            }
                        }
                    }
                }
            }

            .sparky-c-promo-banner__band {
                &:has(.sparky-c-promo-banner__picture) {
                    .sparky-c-promo-banner__body {
                        .sparky-c-promo-banner__content {
                            .sparky-c-promo-banner__description {
                                .sparky-c-text-passage {
                                    .sparky-c-text-passage__inner {
                                        max-width: to-rem(360px);
                                        max-height: to-rem(58px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.sparky-c-promo-banner--center {
            @media screen and (min-width: $sparky-bp-lg) {
                .sparky-c-promo-banner__body {
                    align-items: center;
                }

                .sparky-c-promo-banner__title,
                .sparky-c-promo-banner__description {
                    text-align: center;
                }
            }
        }
    }

    &.sparky-c-promo-banner--center {
        .sparky-c-promo-banner__body {
            justify-content: center;

            .sparky-c-promo-banner__title,
            .sparky-c-promo-banner__description {
                text-align: center;
            }

            .sparky-l-linelength-container {
                margin: 0 auto;
            }
        }
    }

    &.sparky-c-promo-banner--xlg {
        @media screen and (max-width: $sparky-bp-sm-2) {
            margin: size(4) size(-2);

            .sparky-c-promo-banner__band {
                border-radius: 0;

                .sparky-c-promo-banner__picture .sparky-c-picture__image {
                    border-radius: 0;
                }
            }
        }
    }
}
