.legacy-account {
    position: relative;
    height: 76px;
    text-align: center;

    .legacy-account__caret {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-left: 10px;
        border-top: 3px solid var(--sparky-color-neutral-800);
        border-right: 3px solid var(--sparky-color-neutral-800);
        transform: rotate(135deg) translate(-0.25rem, 0.25rem);
        transition: all 333ms ease-out;
    }

    &:hover,
    &:active {
        .legacy-account__caret {
            transform: rotate(-45deg);
        }

        .dropdown-accent,
        .legacy-loggedin__submenu {
            display: block;
        }
    }
}
