@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.tiles4-container {
    width: 100%;

    .tiles-4 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: size(2);

        &--standard {
            height: to-rem(100px);
            text-decoration: none;
            color: var(--sparky-color-brand-blue-500);
            text-align: center;
            padding: size(2);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: size(1);
            border: var(--sparky-border-width-sm) solid
                var(--sparky-theme-color-input-border-disabled);
            border-radius: var(--sparky-border-radius-xl);

            .sparky-c-heading {
                font-size: var(--sparky-font-size-20);
            }

            .sparky-c-text-passage {
                font-size: var(--sparky-font-size-16);
                line-height: var(--sparky-line-height-base);
            }
        }
    }

    @media (min-width: $sparky-bp-md) {
        .tiles-4 {
            &--standard {
                height: to-rem(80px);
                padding: size(0) size(2);

                .sparky-c-heading {
                    font-size: var(--sparky-font-size-24);
                }
            }
        }
    }

    @media (min-width: $sparky-bp-lg) {
        .tiles-4 {
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: size(4);
        }
    }
}
