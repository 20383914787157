@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';
@import './ShopByBrand';

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

// Dropdown layout
.legacy-mainmenu__container {
    @media all and (max-width: $sparky-bp-sm-2) {
        display: none;
    }

    .submenu-container {
        position: absolute;
        top: 100%;
        border-radius: 0 0 size(1) size(1);
        background-color: var(--sparky-color-neutral-white);
        box-shadow: var(--sparky-theme-box-shadow);
        font-family: var(--legacy-header-font-family);
        width: auto;
        max-width: 100%;
        overflow: hidden;
    }

    // As legacy doesn't have any fixed logic for their dropdown (like size and positon),
    // we need to manually set the width and position for each dropdown
    .sparky-c-primary-nav__item {
        // shop-by-brand
        &#shop-by-brand .submenu-container {
            width: to-rem(910px);

            @media all and (min-width: $sparky-bp-lg) {
                width: to-rem(790px);
            }

            @media all and (min-width: $sparky-bp-xl) {
                width: to-rem(950px);
            }
        }

        // shop-by-pet
        &#shop-by-pet .submenu-container {
            width: to-rem(860px);

            @media all and (min-width: $sparky-bp-lg) {
                width: to-rem(784px);
            }

            @media all and (min-width: $sparky-bp-xl) {
                width: to-rem(994px);
            }

            @media all and (min-width: $sparky-bp-xxl) {
                width: to-rem(1080px);
            }

            &__list {
                min-height: fit-content;
            }
        }

        // pet-services
        &#pet-services .submenu-container {
            width: to-rem(648px);
        }

        // sale
        &#sale .submenu-container {
            width: to-rem(250px);

            &__list {
                display: flex;
                flex-direction: column;
                margin-top: to-rem(10px);
                margin-bottom: to-rem(10px);

                .sparky-c-primary-nav__item {
                    padding: 0;
                }
            }
        }

        // featured
        &#featured .submenu-container {
            width: to-rem(250px);

            &__list {
                display: flex;
                flex-direction: column;

                .sparky-c-primary-nav__item {
                    padding: 0;
                }
            }
        }

        // pharmacy
        &#pharmacy .submenu-container {
            right: 0;
            width: to-rem(860px);

            &__list {
                margin-left: to-rem(10px);
            }

            &:has(.submenu-container__data) {
                .submenu-container__data {
                    margin-top: to-rem(50px);
                }
            }

            @media all and (min-width: $sparky-bp-lg) {
                width: to-rem(1080px);
            }
        }

        // help
        &#help .submenu-container {
            width: to-rem(250px);
            right: to-rem(50px);

            &__list {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: to-rem(10px);
                margin-top: to-rem(10px);
                margin-bottom: to-rem(10px);

                .sparky-c-primary-nav__item {
                    padding: 0;

                    .sparky-c-text-link {
                        color: var(--sparky-color-other-hover-1);
                    }
                }
            }
        }
    }

    .submenu-container__data {
        display: flex;

        &:has(.sparky-c-promo-banner) {
            .sparky-c-promo-banner {
                width: 50%;
                position: absolute;
                right: 0;
                bottom: 0;
                margin: 0;

                &__body {
                    padding: size(2) size(4);
                    flex-direction: row;
                }

                &__description {
                    margin-top: 0;
                }

                &__button {
                    margin-top: to-rem(5px);
                    padding: to-rem(10px);
                    align-self: flex-end;
                }
            }
        }

        &:has(.promo-card) {
            justify-content: flex-end;
            position: absolute;
            right: 0;
            bottom: 0;

            .promo-card {
                width: 70%;
            }

            .sparky-c-card {
                &__header {
                    margin: 0;
                }

                &__body {
                    display: none;
                }
            }
        }

        &:has(.sparky-c-text-link) {
            .sparky-c-text-link {
                display: none;
            }
        }
    }
}

.legacy-mainmenu__primary-nav {
    --legacy-header-height: 54px;

    width: 100%;
    height: var(--legacy-header-height);

    .sparky-c-primary-nav {
        &__list {
            position: relative; // For the dropdown menu positioning
            justify-content: space-between;
            gap: 0;
            width: inherit;
        }

        &__link {
            justify-content: center;
            width: 100%;
            height: var(--legacy-header-height);
            color: var(--sparky-color-neutral-white);

            .sparky-c-icon--chevron-down {
                display: none;
            }
        }

        &__item {
            flex: auto;

            > .sparky-c-primary-nav__link {
                border: 0;
                background-color: transparent;
                transition: background-color 0.25s linear 0;
            }

            .sparky-c-primary-nav__item-panel {
                display: block;
                visibility: hidden;
                opacity: 0;
            }

            &:hover {
                > .sparky-c-primary-nav__link {
                    position: relative;
                    background-color: var(--sparky-color-other-hover-1);

                    // White arrow
                    &:has(+ .sparky-c-primary-nav__item-panel)::before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        margin-left: -10px;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid
                            var(--sparky-color-neutral-white);
                    }
                }

                .sparky-c-primary-nav__item-panel {
                    visibility: visible;
                    animation: fadein 0.3s linear forwards;
                }
            }
        }

        &__text {
            font-family: var(--legacy-header-font-family);
            font-size: var(--sparky-font-size-20);
            font-weight: var(--sparky-font-weight-regular);
            line-height: to-rem(54px);
            -webkit-font-smoothing: antialiased;
        }
    }

    // Overlay for dropdown menu
    &:has(
            .sparky-c-primary-nav__item:hover
                > .sparky-c-primary-nav__item-panel
        ) {
        .sparky-c-primary-nav__list {
            z-index: 1001;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1000;
            width: 100%;
            height: 100vh;
            background: rgba(0 0 0 / 25%);
            pointer-events: none;
            animation: fadein 0.3s linear forwards;
        }
    }
}
