@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.sign-in-form {
    display: flex;
    flex-direction: column;
    gap: size(2);

    @media (min-width: $sparky-bp-lg) {
        width: to-rem(368px) !important;
        padding-bottom: size(2.5) !important;
    }

    .sparky-c-field-note {
        line-height: normal;
    }

    &--checkout {
        @media (min-width: $sparky-bp-lg) {
            width: to-rem(416px) !important;
            padding-bottom: size(2.5) !important;
        }
    }

    &__cta {
        width: 100%;

        @media (min-width: $sparky-bp-lg) {
            padding-bottom: size(2.5) !important;
        }

        .sparky-c-button {
            width: 100%;
        }
    }

    &__forgot-password {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
