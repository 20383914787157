@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.location {
    font-family: var(--sparky-font-family-primary);
    color: var(--sparky-color-neutral-900);
    font-size: var(--sparky-font-size-14);
    line-height: var(--sparky-line-height-xxxl);
}

.location-section {
    display: flex;
    align-items: center;

    .location-status-block {
        display: flex;
    }

    .location-status {
        @media all and (min-width: $sparky-bp-sm-2) {
            &::before {
                content: '•';
                color: var(--sparky-color-neutral-900);
                margin: 0 size(1);
            }
        }

        &.location-open {
            color: var(--sparky-color-brand-green-700);
        }

        &.location-closed {
            color: var(--sparky-color-brand-orange-900);
        }
    }
}
