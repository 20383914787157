@import '~common-next/styles/functions';

@mixin divider {
    position: relative;
    margin-bottom: 0;
    padding: size(4) 0;

    &::after {
        display: block;
        content: '';
        height: to-rem(1px);
        background-color: var(--sparky-color-neutral-200);
        width: calc(100% + to-rem(32px));
        position: absolute;
        bottom: 0;
        left: to-rem(-16px);
    }

    @media all and (min-width: $sparky-bp-md) {
        margin-bottom: size(8);
        padding: 0;
        position: static;

        &::after {
            display: none;
        }
    }
}

@mixin truncateText($lines: 2) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}
