@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.brand-spotlight {
    width: 100%;
    display: grid;
    margin-bottom: size(4);
    gap: size(2);

    .sparky-c-heading {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: size(1);
    }

    .sparky-c-button {
        height: size(4);
        margin-top: size(2);
        width: 100%;

        &__text {
            line-height: var(--sparky-line-height-xl);
        }
    }

    .sparky-c-image {
        height: 100%;
        object-fit: cover;
        border-radius: var(--sparky-border-radius-xl);
    }

    &__card {
        display: grid;
        padding: size(3) size(2);
        border-radius: var(--sparky-border-radius-xl);
        text-decoration: none;
        color: inherit;
    }

    &__sm {
        max-height: 105px;
    }

    &__lg {
        max-height: 193px;
    }

    @media (min-width: $sparky-bp-md) {
        grid-auto-rows: auto 1fr;
        grid-template-columns: 1fr 1fr max-content;
        gap: size(3);

        .sparky-c-button {
            width: fit-content;
        }

        &__sm {
            grid-column: span 3;
            order: 0;
        }

        &__lg {
            max-height: 100%;
            grid-column: span 2;
            order: 1;
        }

        &__card {
            padding: size(3);
            place-content: center;
            max-width: 274px;
            order: 2;
        }
    }

    @media (min-width: $sparky-bp-lg) {
        grid-auto-rows: 1fr;
        grid-template-columns: repeat(5, 1fr);
        margin-bottom: size(8);
        gap: size(4);

        .sparky-c-heading {
            font-size: size(4);
            line-height: size(5);
        }

        .sparky-c-image {
            max-height: 269px;
        }

        &__sm {
            grid-row: 1;
            max-height: 100%;
            grid-column: span 1;
            order: 2;
        }

        &__lg {
            grid-row: 1;
            grid-column: span 2;
        }

        &__card {
            grid-row: 1;
            grid-column: span 2;
            padding: size(3) size(4);
            max-width: unset;
            order: 0;
        }
    }
}
