@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.link-list {
    justify-content: right;
    column-gap: 0;

    &__drawer {
        margin-top: 16px;

        // tablet only
        @media all and (min-width: $sparky-bp-md) and (max-width: $sparky-bp-xl) {
            padding-left: 16px;
        }
    }

    .sparky-c-icon {
        margin-left: 4px;
    }

    &--normal {
        a {
            color: var(--sparky-color-neutral-900);
            align-items: center;
        }
    }

    &--big {
        a {
            color: var(--sparky-color-neutral-900);
            font-size: var(--sparky-font-size-14);
            align-items: center;
        }

        &--sign-in {
            a {
                color: var(--sparky-theme-color-primary-foreground);
                font-size: var(--sparky-font-size-14);
                align-items: center;
            }
        }
    }

    .sparky-c-link-list {
        &__icon {
            position: inherit;
        }
    }
}
