@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.c-promo-carousel {
    width: 100%;
    margin-bottom: size(4);
    text-align: center;

    &__content {
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        gap: size(2);
        margin-top: size(2);
    }

    &__navs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: size(1);
        text-align: left;
    }

    &__carousel {
        border-radius: var(--sparky-border-radius-xl);
        overflow: hidden;
        text-align: left;
    }

    .sparky-c-horizontal-overflow-carousel__button-prev,
    .sparky-c-horizontal-overflow-carousel__button-next {
        display: none;
    }

    .sparky-c-horizontal-overflow-carousel__list {
        padding-block: 0;
    }

    @media (min-width: $sparky-bp-md) {
        &__content {
            gap: size(3);
        }

        &__navs {
            gap: inherit;
            grid-template-columns: repeat(4, 1fr);
        }
    }

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);

        .sparky-c-horizontal-overflow-carousel__button-prev,
        .sparky-c-horizontal-overflow-carousel__button-next {
            display: block;
        }

        &__content {
            gap: size(4);
            grid-template-columns: 1fr minmax(0, 2fr);
        }

        &__navs {
            grid-template-columns: repeat(2, 1fr);
        }

        &__carousel {
            height: 392px;
        }
    }
}
