@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.tile6up-promo {
    .sparky-c-tile {
        picture {
            max-width: size(17);

            @media all and (min-width: $sparky-bp-lg) {
                max-width: size(45);
            }
        }

        &__title {
            font-size: var(--sparky-font-size-14);
            text-align: center;
        }
    }

    .sparky-c-horizontal-overflow-carousel__button {
        width: size(5);
        height: size(5);
    }

    $sparky-override-container: 960px;

    @media (min-width: $sparky-override-container) {
        .carousel-outer-item {
            min-width: 180px !important;
            width: 180px !important;
            margin-inline: auto !important;
        }

        .carousel-inner {
            gap: size(4);
        }

        &.xl-even-spacing {
            .carousel-inner {
                gap: unset;
            }
        }
    }
}
