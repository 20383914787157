@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.card-3up-article {
    $sparky-container-max-width: 1304px;

    .sparky-c-horizontal-overflow-carousel__item {
        .sparky-c-text-passage {
            margin-bottom: 16px;
            min-height: 39px;
        }

        .sparky-c-text-link {
            margin: 0;
            padding: 0;
        }
    }

    // The sparky container is a wrapper to each component that sets a large max-width
    // and the HorizontalOverflowCarousel ends up using all of the width available
    // causing a huge overflow, so these below are meant to avoid this behavior.
    @media all and (max-width: $sparky-bp-md) {
        width: calc(100vw - size(4.5));
    }

    @media all and (min-width: $sparky-bp-md) and (max-width: $sparky-container-max-width) {
        width: calc(100vw - size(8));
    }

    // Mobile only
    @media all and (max-width: $sparky-bp-md) {
        .sparky-c-horizontal-overflow-carousel__inner {
            width: 100%;
            position: relative;
            overflow-x: auto;

            .sparky-c-horizontal-overflow-carousel__list {
                padding: 0 size(2) 0 0;
            }
        }
    }

    // Tablet only
    @media all and (min-width: $sparky-bp-md) and (max-width: $sparky-bp-xl) {
        .sparky-c-horizontal-overflow-carousel__inner {
            width: 100%;
            position: relative;
            overflow-x: auto;

            .sparky-c-horizontal-overflow-carousel__list {
                padding: 0 size(4) 0 0;
                width: calc(100% + var(--sparky-size-base-unit) * 4);
            }
        }

        .sparky-c-horizontal-overflow-carousel__controls {
            display: none;
        }
    }
}
