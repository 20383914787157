@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.card-up {
    &__cardInfo-grd {
        margin: 0;
        padding: 0 to-rem(16px);
        justify-content: space-between;
        flex-direction: row;

        @media all and (min-width: $sparky-bp-md) {
            padding: 0;
        }

        .sparky-l-grid__item {
            width: 50%;
            padding: 0 0 to-rem(16px);
            text-align: center;

            &:nth-child(even) {
                padding-left: to-rem(8px);
            }

            &:nth-child(odd) {
                padding-right: to-rem(8px);
            }

            @media all and (min-width: $sparky-bp-md) {
                padding: to-rem(12px);

                &:nth-child(even) {
                    padding-left: to-rem(12px);
                }

                &:nth-child(odd) {
                    padding-right: to-rem(12px);
                }
            }

            @media all and (min-width: $sparky-bp-xl) {
                max-width: to-rem(286px);
                padding: 0;

                &:nth-child(even) {
                    padding-left: 0;
                }

                &:nth-child(odd) {
                    padding-right: 0;
                }
            }

            .sparky-c-picture__image {
                width: to-rem(180px);
                max-height: to-rem(180px);
                margin: 0 auto;
                max-width: to-rem(104px);

                @media all and (min-width: $sparky-bp-xl) {
                    max-width: none;
                }
            }

            .sparky-c-heading {
                margin-bottom: to-rem(8px);
            }

            .sparky-c-card__header {
                margin-bottom: to-rem(16px);
            }
        }
    }
}
