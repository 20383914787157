@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '~common-next/styles/functions';

.upper-nav-container {
    border-bottom: 1px solid var(--sparky-color-neutral-200);
    padding: 0.9rem 0;

    &__left-section {
        display: block;

        .location-section {
            justify-content: space-between;
        }

        @media all and (min-width: $sparky-bp-sm-2) {
            display: flex;

            .location-section {
                justify-content: initial;
            }
        }
    }

    &__secondary-link-section {
        display: none;

        @media all and (min-width: $sparky-bp-lg) {
            display: block;
        }
    }

    // sparky target selector
    .sparky-l-container {
        .layout {
            display: block;

            @media all and (min-width: $sparky-bp-lg) {
                display: grid;
                grid-gap: 0;
                gap: 0;
                grid-template-columns: 50fr 50fr;
            }
        }
    }

    .sparky-c-link-list {
        .sparky-c-text-link {
            line-height: var(--sparky-line-height-xxxl);
            font-size: var(--sparky-font-size-14);
            color: var(--sparky-color-neutral-900);
        }
    }
}

.global-header {
    z-index: var(--sparky-z-index-400);

    // Although this class is applied by sparky, it has no styles.
    &.sparky-c-header--sticky {
        position: sticky;
        top: 0;
    }

    .hidden {
        display: none;
    }

    .top-header-components {
        overflow: hidden;
    }

    &.is-sticky {
        .sparky-c-header__middle {
            .middle-header-container {
                display: flex;
                padding-left: to-rem(8px);
                padding-right: to-rem(8px);

                .sparky-c-utility-nav {
                    margin-left: to-rem(8px);
                }

                .sparky-c-logo {
                    display: none;
                }
            }
        }
    }

    @media all and (min-width: $sparky-bp-sm-2) {
        &.is-sticky {
            .sparky-c-header__middle {
                .middle-header-container {
                    margin-left: auto;
                    margin-right: auto;
                    padding-left: to-rem(32px);
                    padding-right: to-rem(32px);

                    .sparky-c-utility-nav {
                        margin-left: auto;
                    }

                    .sparky-c-logo {
                        display: block;
                    }
                }
            }
        }
    }

    // Adapt HeaderSparky.Middle to become a wrapper.
    .middle-header-wrapper {
        box-shadow: var(--sparky-theme-box-shadow);
        display: block;
        padding-left: 0;
        padding-right: 0;
        width: 100vw;
        position: relative;
        max-width: 100%;
    }

    .middle-header-container {
        // Assign properties from HeaderSparky.Middle to LayoutContainer
        display: grid;
        place-items: center;
        row-gap: to-rem(8px);
        width: 100%;
        grid-template:
            'menu      logo      utils' 1fr
            'searchbox searchbox searchbox' 1fr / 1fr 2fr 1fr;

        .sparky-c-header__middle {
            @media all and (min-width: $sparky-bp-lg) {
                height: 80px;
            }

            padding-left: 0;
            padding-right: 0;
            justify-content: flex-start;
            gap: 0;

            .sparky-c-header__menu-button {
                min-width: 48px;
                height: 48px;
            }
        }

        @media all and (min-width: $sparky-bp-sm-2) {
            display: flex;

            .sparky-c-header__menu-button {
                margin-left: 28px;
                margin-right: 28px;
            }
        }

        .sparky-c-utility-nav {
            justify-self: flex-end;
            grid-area: utils;

            @media all and (min-width: $sparky-bp-sm-2) {
                padding-left: 16px;
            }

            &__list {
                gap: 0;
                display: flex;
                align-items: flex-end;

                @media all and (min-width: $sparky-bp-sm-2) {
                    gap: 8px;
                }
            }

            &__link {
                align-items: center;
                height: 100%;
                justify-content: center;
                width: 100%;
            }

            &__item {
                height: 48px;
                width: 48px;

                svg {
                    margin: 0;
                    height: 24px;
                    width: 24px;
                }

                &.shopping-cart .sparky-c-notification {
                    background-clip: padding-box;
                    background-color: var(--sparky-color-brand-red-700);
                }
            }
        }

        .sparky-c-header__menu-button {
            min-width: to-rem(48px);
            height: to-rem(48px);
            justify-self: start;
            grid-area: menu;

            @media screen and (max-width: $sparky-bp-sm-2) {
                margin-right: to-rem(20px);
            }
        }

        .sparky-c-logo {
            min-width: to-rem(120px);
            grid-area: logo;

            &__img {
                width: to-rem(120px);
                height: to-rem(30px);
            }
        }

        @media all and (min-width: $sparky-bp-sm-2) {
            max-width: calc(
                var(--sparky-l-max-width) + var(--sparky-size-base-unit) * 6
            );
            margin-left: auto;
            margin-right: auto;
            padding-left: to-rem(32px);
            padding-right: to-rem(32px);
            justify-items: normal;
            row-gap: normal;
            width: auto;
            grid-template: none;

            .sparky-c-header__menu-button {
                min-width: to-rem(48px);
                height: to-rem(48px);
                justify-self: start;
                grid-area: menu;
            }

            .sparky-c-logo {
                max-width: calc(var(--sparky-size-base-unit) * 11);
                grid-area: unset;
            }
        }

        // Reorder the logo and the menu button for tablet sizes.
        @media all and (min-width: $sparky-bp-sm-2) and (max-width: $sparky-bp-lg) {
            .sparky-c-logo {
                order: 1;
            }

            .sparky-c-header__menu-button {
                order: 2;
            }

            .autocomplete {
                order: 3;
            }

            .sparky-c-utility-nav {
                order: 4;
            }
        }
    }
}
