@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.six-tile-carousel-container {
    position: relative;

    $sparky-container-max-width: 1304px;
    $container-override-max-width: calc($sparky-bp-md + 1rem);

    @media screen and (max-width: $container-override-max-width) {
        overflow: visible;
        padding-left: 0;

        .sparky-c-horizontal-overflow-carousel__inner {
            overflow-x: auto;
        }

        .sparky-c-horizontal-overflow-carousel__list {
            transform: none !important;
        }

        .sparky-c-horizontal-overflow-carousel-wrapper {
            overflow: scroll;
            scroll-behavior: smooth;
        }
    }

    .sparky-c-horizontal-overflow-carousel__list {
        &:not([style]) {
            position: relative;
            z-index: 999;

            &::before {
                transform: translateX(to-rem(-100px));
                width: to-rem(99999px);
                pointer-events: none;
                position: absolute;
                background: #fff;
                height: 100%;
                z-index: 999;
                content: '';
            }
        }
    }

    .tile6up-shoppable {
        .sparky-c-picture {
            max-width: none;

            @media all and (min-width: $sparky-bp-md) {
                max-width: size(22.5);
            }
        }

        .sparky-c-tile {
            max-width: none;
            text-align: center;
        }

        .sparky-c-horizontal-overflow-carousel__list {
            justify-content: space-around;

            @media screen and (min-width: $sparky-bp-md) and (max-width: $sparky-bp-xxl) {
                justify-content: space-evenly;
            }
        }

        .sparky-c-horizontal-overflow-carousel__item {
            max-width: to-rem(180px);
            margin: 0 !important;

            @media screen and (max-width: $sparky-bp-sm-2) {
                max-width: to-rem(136px);
            }
        }
    }

    .tile6up-visual-nav {
        .sparky-c-picture {
            max-width: size(12);
        }

        .sparky-c-tile {
            margin: 0 auto;
            text-align: center;
        }

        &.xl-even-spacing .sparky-c-horizontal-overflow-carousel__list {
            @media all and (min-width: $sparky-bp-lg) {
                gap: size(14.5);
            }
        }

        .sparky-c-tile__title {
            font-size: var(--sparky-font-size-14);
        }
    }

    .tile6up-shoppable .sparky-c-horizontal-overflow-carousel__item.xl-static,
    .tile6up-visual-nav {
        .sparky-c-horizontal-overflow-carousel__item.xl-static {
            @media all and (min-width: $sparky-bp-lg) {
                width: auto !important;
            }
        }
    }

    .tile6up-promo {
        .sparky-c-horizontal-overflow-carousel__item {
            display: grid;
            justify-items: center;
            align-self: flex-start;
        }

        .sparky-c-tile__title {
            align-self: center;
        }

        @media all and (max-width: $sparky-bp-lg) {
            width: 100%;
        }
    }
}
