@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '~common-next/styles/functions';

.sparky-l-container.feature-banner-container {
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;

    @media (min-width: $sparky-bp-md) {
        margin-inline: auto;
    }

    @media (min-width: $sparky-bp-lg) {
        padding-inline: size(4);
    }
}

.feature-banner {
    margin-bottom: size(4);
    border-radius: 0;

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);
        height: to-rem(400px);
        border-radius: var(--sparky-border-radius-xl);
    }

    .sparky-c-heading {
        font-size: var(--sparky-font-size-24);

        @media (min-width: $sparky-bp-md) and (max-width: $sparky-bp-lg) {
            font-size: var(--sparky-font-size-20);
        }
    }

    .sparky-c-feature__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: to-rem(92px) to-rem(64px);
        align-self: center;

        @media (min-width: $sparky-bp-md) and (max-width: $sparky-bp-lg) {
            height: to-rem(280px);
            padding: to-rem(80px) to-rem(64px);
        }

        @media (min-width: $sparky-bp-lg) {
            height: to-rem(400px);
            width: to-rem(530px);
        }

        @media (max-width: $sparky-bp-lg) {
            order: 2;
            padding: to-rem(24px) to-rem(15px) to-rem(24px) to-rem(16px);
        }
    }

    .sparky-c-feature__media {
        background-color: var(--sparky-color-neutral-800);
        min-width: 57.26%;
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 100%;

        video {
            position: absolute;
            width: 100%;
            top: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media (min-width: $sparky-bp-md) and (max-width: $sparky-bp-lg) {
                height: to-rem(280px);
            }
        }

        .feature-banner-video-container {
            padding-top: 56.25%;
            position: relative;
            width: 100%;

            iframe,
            video {
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
            }

            video {
                position: absolute;
                width: 100%;
                top: 0;
                height: 100% !important;
            }

            @media (min-width: $sparky-bp-md) and (max-width: $sparky-bp-lg) {
                height: to-rem(280px);
            }

            @media (min-width: $sparky-bp-lg) {
                height: to-rem(400px);
            }
        }
    }

    .sparky-c-text-passage {
        font-size: var(--sparky-font-size-14);
        margin-top: to-rem(16px);
        margin-bottom: to-rem(32px);

        &:is(:last-child) {
            margin-bottom: 0;
        }

        @media all and (max-width: $sparky-bp-lg) {
            margin-top: to-rem(8px);
            margin-bottom: to-rem(16px);
        }
    }

    &__link {
        text-decoration: none;

        .sparky-c-button {
            text-decoration: none;
            height: to-rem(48px);
        }
    }
}
