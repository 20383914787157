@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.carousel-container {
    position: relative;
    overflow: hidden;
}

// Styles for desktop carousel

.carousel {
    display: flex;
    cursor: grab;
    width: 100%;
    transition: transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1); /* Smooth transition for dragging */
}

.carousel-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.carousel-outer-item {
    /* Values used just for server-side render */
    min-width: calc(
        100% / 6
    ); /* Display 6 items at once as starting point value */

    @media screen and (max-width: $sparky-bp-lg) {
        min-width: calc(100% / 3.5);
    }

    @media screen and (max-width: $sparky-bp-sm-2) {
        min-width: calc(100% / 2.5);
    }
}

.carousel-arrow-left {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
}

.carousel-arrow-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: auto;
    right: 10px;
}

// Styles for mobile carousel

.carousel-mobile {
    overflow-x: scroll;
    display: flex;
    grid-row-start: auto;
    gap: 5px;

    &::-webkit-scrollbar {
        display: none;
    }

    .carousel-outer-item {
        display: flex;
        min-width: 40vw;
        min-height: 200px;
        margin-bottom: 10px;
    }
}
