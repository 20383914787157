@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.benefit-items {
    display: flex;
    flex-direction: column;
    gap: size(2);

    &__item {
        display: flex;
        align-items: center;
        gap: size(2);

        &--icon {
            width: size(4);
            height: size(4);
            object-fit: contain;
        }
    }
}
