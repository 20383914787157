@import '@petsmart-ui/sparky/dist/scss/functions';

.legacy-mainmenu__primary-nav {
    .sparky-c-primary-nav__item:hover {
        .shop-by-brand.submenu-container {
            padding-top: 26px;
            padding-bottom: 0;

            .submenu-container__data {
                display: inline-flex;
            }
        }
    }

    .shop-by-brand.submenu-container {
        max-width: fit-content !important;

        .submenu-container {
            &__list {
                display: grid;
                gap: 30px;
                grid-template-columns: repeat(6, 120px);
                padding-right: 40px;

                .category__item--image {
                    box-shadow: 0 0 size(1) rgba(0 0 0 / 49%);
                    border-radius: 5px;
                    padding: 0;

                    &:hover {
                        box-shadow: 0 0 size(2) rgba(0 0 0 / 75%);
                    }

                    .sparky-c-text-link {
                        &:active,
                        &:focus {
                            text-decoration: none;
                            outline: none;
                        }
                    }
                }
            }

            &__data {
                width: 100%;
                justify-content: center;
                align-items: center;
                max-width: 100%;
                padding: 0;

                #all-brands-link a {
                    color: var(--sparky-theme-color-link-foreground);
                    font-family: 'Proxima Nova', sans-serif;
                    font-size: var(--sparky-font-size-24);
                    font-weight: var(--sparky-font-weight-bold);
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .submenu__layout {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            padding: 0;
        }
    }
}
