@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.campaign-cards-2up {
    width: 100%;
    margin-bottom: size(4);
    text-align: center;
    padding: 0;

    .sparky-c-image {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        max-height: 250px;
    }

    &__content {
        padding: size(3) size(2);
    }

    &__header {
        display: grid;
        gap: size(0.5);
        align-content: center;

        .sparky-c-text-link {
            justify-self: center;
        }
    }

    &__cards {
        display: grid;
        row-gap: size(3);
        column-gap: size(2);
        margin-top: size(2);
        grid-template-columns: 1fr;
        align-items: start;
    }

    &__card {
        color: unset;
        margin-inline: auto;
        text-decoration: none;
        max-width: 343px;
        width: 100%;

        &-body {
            display: grid;
            border-radius: var(--sparky-border-radius-xl);
            margin-bottom: size(2);
            overflow: hidden;
        }

        &-content {
            display: grid;
            text-align: center;
            align-content: start;
            gap: size(1);
        }

        &-text {
            font-weight: 700;
            font-size: 18px;
            line-height: size(3);
        }

        &-terms {
            font-size: var(--sparky-font-size-12);
            line-height: var(--sparky-line-height-xl);
        }

        &-clip {
            margin: auto;
            padding: size(2);
            align-content: center;
        }

        .sparky-c-heading {
            font-size: size(6);
            line-height: size(7);
            font-weight: var(--sparky-font-weight-bold);
        }
    }

    @media (min-width: $sparky-bp-md) {
        .sparky-c-heading {
            font-size: var(--sparky-font-size-32);
            line-height: var(--sparky-line-height-md);
        }

        &__content {
            padding: size(3) size(4);
        }

        &__cards {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, auto);
            column-gap: size(3);
            row-gap: size(2);
        }

        &__card {
            display: grid;
            max-width: 100%;
            grid-template-rows: subgrid;
            grid-row: 1 / 4;

            &-body {
                display: grid;
                grid-template-rows: subgrid;
                margin-bottom: 0;
                grid-row: 1 / 3;
                gap: 0;
            }

            .sparky-c-heading {
                font-size: size(6);
                line-height: size(7);
            }
        }
    }

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);
        padding-inline: size(4);

        .sparky-c-heading {
            font-size: var(--sparky-font-size-40);
            line-height: var(--sparky-line-height-xs);
        }

        &__content {
            padding: size(4);
            border-radius: var(--sparky-border-radius-xl);
            overflow: hidden;
        }

        &__cards {
            column-gap: size(4);
            grid-template-rows: repeat(2, auto);
        }

        &__card {
            grid-row: 1 / 3;

            &-body {
                grid-template-columns: 1fr auto;
                grid-row: 1 / 2;
            }

            &-text {
                font-size: 20px;
                line-height: 28px;
            }

            &-clip {
                min-width: 239px;
                padding: size(3) 0;
                order: -1;
            }

            .sparky-c-heading {
                font-size: size(7);
                line-height: size(8);
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                max-height: 250px;
                overflow: hidden;
                padding: 0 size(3);
            }
        }
    }
}
