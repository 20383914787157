@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.contentful-container {
    width: 100%;
    padding: 0;
    margin-bottom: size(4);

    &:empty {
        display: none;
    }

    & > div {
        margin-bottom: 0;
    }

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);

        & > div,
        & > div > :first-child {
            overflow: hidden;
            border-radius: 0;
        }

        @mixin border-radius($selector) {
            $radius: var(--sparky-border-radius-xl);

            & > div:#{$selector},
            & > div:#{$selector} > :first-child {
                overflow: hidden;

                @if $selector == 'first-child' {
                    border-top-left-radius: $radius;
                    border-top-right-radius: $radius;
                } @else {
                    border-bottom-left-radius: $radius;
                    border-bottom-right-radius: $radius;
                }
            }
        }

        @include border-radius('last-child');

        &__br-all {
            @include border-radius('first-child');
        }
    }
}
