@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.campaign-hero-split {
    display: flex;
    flex-direction: column-reverse;
    text-decoration: none;

    &__info-container {
        align-content: center;
        padding: size(3) size(2);
    }

    .sparky-c-image {
        height: 100%;
        object-fit: cover;
    }

    .sparky-c-picture {
        height: to-rem(184px);
    }

    .preheader {
        font-size: size(2.5);
        line-height: size(3.5);
    }

    .header {
        font-size: size(6);
        line-height: size(7);
        margin-bottom: size(2);
    }

    .sparky-c-text-passage {
        font-size: size(2);
        line-height: size(3);
        margin-bottom: size(2);
    }

    @media (min-width: $sparky-bp-md) {
        .sparky-c-picture {
            height: to-rem(400px);
        }
    }

    @media (min-width: $sparky-bp-lg) {
        flex-direction: row;

        .sparky-c-picture {
            min-height: to-rem(400px);
        }

        &__info-container {
            width: 100%;
            max-width: to-rem(424px);
            padding: size(2.5) size(4);
        }

        .preheader {
            font-size: size(3);
            line-height: size(4);
        }

        .header {
            font-size: size(7);
            line-height: size(8);
        }
    }
}
