@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.campaign-cards-4up {
    width: 100%;
    margin-bottom: size(4);
    text-align: center;
    padding: 0;

    .sparky-c-image {
        width: 100%;
        display: block;
        object-fit: cover;
    }

    .sparky-c-heading {
        font-size: size(4);
        line-height: size(5);
    }

    &__content {
        padding: size(3) size(2);
    }

    &__header {
        display: grid;
        gap: size(1);
    }

    &__cards {
        display: grid;
        column-gap: size(1);
        margin-top: size(2);
        grid-auto-flow: column;
        align-items: start;
        overflow-x: auto;
        margin-inline: size(-2);
        justify-content: start;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__card {
        width: 270px;
        display: grid;
        grid-template-rows: 1fr auto;
        border-radius: var(--sparky-border-radius-xl);
        overflow: hidden;
        height: 100%;
        text-decoration: none;
        margin-inline: auto;
        color: unset;

        &:first-of-type {
            margin-left: size(2);
        }

        &:last-of-type {
            margin-right: size(2);
        }

        &-content {
            display: grid;
            text-align: left;
            padding: size(2);
            align-content: start;
        }

        &-preheader {
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
        }

        &-subheader {
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;
            max-width: min-content;
        }

        &-heading-raw {
            display: flex;
            align-items: center;
            gap: size(0.5);
        }

        &-body {
            font-size: var(--sparky-font-size-14);
            line-height: var(--sparky-line-height-xxxl);
            margin-bottom: size(1);
        }

        &-terms {
            font-size: var(--sparky-font-size-12);
            line-height: var(--sparky-line-height-xl);
        }

        .sparky-c-heading {
            font-size: size(6);
            line-height: size(7);
            font-weight: 700;
        }
    }

    @media (min-width: $sparky-bp-md) {
        &__content {
            padding: size(3) size(4);
        }

        &__cards {
            column-gap: size(3);
            margin-inline: size(-4);
        }

        &__card {
            &:first-of-type {
                margin-left: size(4);
            }

            &:last-of-type {
                margin-right: size(4);
            }
        }
    }

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);
        padding-inline: size(4);

        &__content {
            padding: size(4);
            border-radius: var(--sparky-border-radius-xl);
            overflow: hidden;
        }

        &__cards {
            column-gap: size(4);
        }

        .sparky-c-heading {
            font-size: size(5);
            line-height: size(6);
        }

        &__card {
            &-preheader {
                font-size: var(--sparky-font-size-20);
                line-height: var(--sparky-line-height-xxl);
            }

            &-subheader {
                font-size: 20px;
                line-height: 22px;
            }

            .sparky-c-heading {
                font-size: size(7);
                line-height: size(8);
            }
        }
    }
}
