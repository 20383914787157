@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.faq {
    margin-inline: size(5);
    margin-bottom: size(4);

    @media (min-width: $sparky-bp-lg) {
        &-centered {
            padding-inline: size(4);
            margin-inline: auto;
        }

        margin-bottom: size(8);
    }

    &-subheader {
        margin-top: size(1);
    }

    &-accordions {
        display: grid;
        grid-auto-flow: column;
        gap: size(2);
        margin-top: size(2);

        @media (min-width: $sparky-bp-md) {
            gap: size(4);
        }
    }

    &-list {
        padding-left: size(2);

        &-cards {
            padding-left: 0;
        }
    }

    &-question {
        .sparky-c-text-passage {
            padding-left: 0;
        }

        .sparky-c-accordion-panel__header {
            margin-bottom: 0 !important;
        }
    }

    &-accordion-group {
        border-top: var(--sparky-theme-border-width) solid
            var(--sparky-theme-color-neutral-subtle-border);

        &:empty {
            border-top: none;
        }
    }
}
