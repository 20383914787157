@import '@app/acp/globalStyles/account.vars';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.password-confirmation-drawer {
    z-index: var(--sparky-z-index-top);

    .sparky-c-drawer__header {
        padding: size(2) !important;

        #drawer-heading-1 {
            font-size: var(--sparky-font-size-20) !important;
        }
    }

    .sparky-l-grid {
        @media screen and (min-width: $sparky-bp-md) {
            margin-top: 0;
        }

        &__item {
            padding-top: 0;
            padding-bottom: 0;

            &:first-child {
                padding-bottom: size(2);
            }

            &:last-child {
                padding-top: size(3);

                @media screen and (min-width: $sparky-bp-lg) {
                    padding-top: size(4);
                }
            }
        }
    }
}
