@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.legacy-usablenet__link {
    font-family: var(--legacy-header-font-family) !important;
    font-size: var(--sparky-font-size-16) !important;

    @media screen and (max-width: $sparky-bp-lg) {
        text-align: center;
        line-height: 40px !important;
    }
}
