@import '@petsmart-ui/sparky/dist/scss/functions';

.divider {
    height: 0;
    margin: size(2) 0;
    overflow: hidden;
    border-top: var(--sparky-theme-border-width) solid
        var(--sparky-theme-color-neutral-subtle-border);
    opacity: 1;
}
