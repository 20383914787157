@import '@petsmart-ui/sparky/dist/scss/functions';

.seo-block {
    &__wrapper {
        .sparky-l-linelength-container {
            max-width: unset;
        }
    }

    &__button {
        margin-top: size(1);
        padding-bottom: size(4);
        width: size(9);
    }

    .sparky-c-button--link {
        line-height: calc(var(--sparky-size-base-unit) * 2.5);
    }
}
