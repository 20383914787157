@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.interstitial-sign-in {
    display: flex;
    flex-direction: column;

    .sparky-c-legend {
        margin-bottom: 0;
    }

    &__headings {
        display: flex;
        flex-direction: column;
        gap: to-rem(16px);
        text-align: center;
        padding-bottom: to-rem(24px);

        @media (min-width: $sparky-bp-lg) {
            padding-bottom: to-rem(32px);
        }
    }

    &__benefits-container {
        display: flex;
        flex-direction: column;
        gap: to-rem(16px);
        margin-top: to-rem(32px);
        margin-bottom: to-rem(24px);
    }
}
