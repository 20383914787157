@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.campaign-cards-3up {
    width: 100%;
    margin-bottom: size(4);
    text-align: center;
    padding: 0;

    .sparky-c-image {
        width: 100%;
        display: block;
        object-fit: cover;
    }

    &__content {
        display: grid;
        gap: size(0.5);
        padding: size(3) size(2);
    }

    &__header {
        display: grid;
        gap: size(0.5);
    }

    &__cards {
        display: grid;
        gap: size(2);
        margin-top: size(2);
        grid-template-columns: 1fr;
        align-items: start;
    }

    &__card {
        display: grid;
        grid-template-rows: 1fr auto;
        border-radius: var(--sparky-border-radius-xl);
        overflow: hidden;
        max-width: 343px;
        height: 100%;
        text-decoration: none;
        margin-inline: auto;
        color: unset;
        width: 100%;

        &-content {
            display: grid;
            text-align: left;
            padding: size(2);
            align-content: start;
        }

        &-preheader {
            width: 100%;
            font-size: var(--sparky-font-size-28);
            line-height: 36px;
            font-weight: var(--sparky-font-weight-bold);
            overflow: hidden;

            div {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &-terms {
            font-size: var(--sparky-font-size-12);
            line-height: var(--sparky-line-height-xl);
            margin-top: size(1);
        }

        &-body {
            font-size: size(2);
            line-height: size(3);
        }

        .sparky-c-heading {
            font-size: size(6);
            line-height: size(7);
            font-weight: var(--sparky-font-weight-bold);
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    @media (min-width: $sparky-bp-md) {
        &__content {
            padding: size(3) size(4);
        }

        &__card {
            max-width: 100%;
        }

        &__cards {
            grid-template-columns: repeat(3, 1fr);
            gap: size(3);
        }

        .sparky-c-heading {
            font-size: size(4);
        }
    }

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);
        padding-inline: size(4);

        &__content {
            padding: size(4);
            border-radius: var(--sparky-border-radius-xl);
            overflow: hidden;
        }

        &__cards {
            gap: size(4);
        }

        &__card {
            &-preheader {
                font-size: size(4);
                line-height: size(5);
            }

            .sparky-c-heading {
                font-size: size(7);
                line-height: size(8);
            }
        }

        .sparky-c-heading {
            font-size: size(5);
        }
    }
}
