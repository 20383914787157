@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.mainmenu__container {
    width: unset;
    padding-left: size(2);
    padding-right: size(2);
    display: none;

    @media all and (min-width: $sparky-bp-xl) {
        display: block;
    }
}

.mainmenu__primary-nav {
    .sparky-c-primary-nav {
        &__list {
            gap: size(1);
        }

        &__item {
            &.highlighted {
                .sparky-c-primary-nav__text,
                .sparky-c-primary-nav__link {
                    color: var(--sparky-color-brand-red-700);
                }
            }

            > .sparky-c-primary-nav__link {
                border: 1px solid var(--sparky-color-neutral-white);
                background-color: var(--sparky-color-neutral-white);
                padding: 0;
            }

            .sparky-c-primary-nav__item-panel {
                display: block;
                visibility: hidden;
                opacity: 0;
                transition:
                    visibility 0.25s linear 0,
                    opacity 0.25s linear 0;
            }

            &:focus-within {
                > .sparky-c-primary-nav__link {
                    position: relative;
                    background-color: var(
                        --sparky-theme-color-neutral-subtle-border
                    );
                    border: 1px solid
                        var(--sparky-theme-color-neutral-subtle-border);
                    border-radius: 4px;
                    cursor: pointer;
                }

                > button.sparky-c-primary-nav__link {
                    // Hack to keep hover/focus area  between link and the .submenu-container
                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -50%;
                        width: 100%;
                        height: 50%;
                        background: transparent;
                    }
                }

                .sparky-c-primary-nav__item-panel {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &__link {
            height: 48px;
            align-items: center;
            justify-content: center;

            .sparky-c-icon--chevron-down {
                display: none;
            }
        }

        &__text {
            margin: 0 size(2);
            font-size: var(--sparky-font-size-16);
            line-height: var(--sparky-font-size-24);
        }
    }

    // Overlay for dropdown menu
    > .sparky-c-primary-nav__list > .sparky-c-primary-nav__item {
        &:hover {
            > .sparky-c-primary-nav__link {
                cursor: pointer;
            }
        }

        &:focus-within {
            > .sparky-c-primary-nav__item-panel {
                > .submenu-container {
                    z-index: 1001;
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 1001;
                    width: 100vw;
                    max-width: 100%;
                    height: 100vh;
                    background: rgba(0 0 0 / 25%);
                    pointer-events: none;
                }
            }
        }
    }
}

.mainmenu__container .submenu-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    background-color: var(--sparky-color-neutral-white);
    box-shadow: inset 0 2px 4px rgba(0 0 0 / 8%);
    font-family: var(--sparky-font-family-primary);
    max-width: 100%;

    .submenu__layout {
        display: flex;
        flex-direction: row;
        gap: size(4);
        padding: 0;
        max-width: calc(
            var(--sparky-l-max-width) + var(--sparky-size-base-unit) * 6
        );
        margin: 0 auto;

        &:not(.submenu__layout--tabs) {
            margin-bottom: size(4);
        }

        &--card {
            gap: 0;
            flex-direction: column;
            align-items: center;

            > a {
                text-decoration: none;
            }
        }
    }

    .submenu-container__list {
        flex: auto;

        &:not(.sparky-c-tabs) {
            padding: size(4);
        }

        &.card-list {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            gap: size(4);

            li {
                display: block;
            }

            .menu-card {
                width: 100%;
                max-width: to-rem(180px);

                img {
                    height: to-rem(101px);
                    object-fit: cover;
                }

                a {
                    text-decoration: none;
                }

                h3 {
                    color: var(--sparky-color-neutral-900);
                }

                p {
                    color: var(--sparky-color-neutral-900);
                    font-size: var(--sparky-font-size-14);
                    line-height: var(--sparky-font-size-20);
                }
            }

            + a {
                color: var(--sparky-color-brand-blue-500);
            }
        }

        &.larger-cards {
            .menu-card {
                max-width: to-rem(222px);

                img {
                    width: to-rem(222px);
                    height: to-rem(125px);
                }
            }
        }
    }

    // Marketing card and shop all link section
    .submenu-container__data {
        padding: size(4) 0;
        width: size(35.75);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > a.promo-card .sparky-c-card__body {
            flex: none;
        }

        .promo-card-header {
            font-size: var(--sparky-font-size-14) !important;
        }

        .promo-card__link {
            margin-top: size(1.5);
            color: var(--sparky-color-neutral-900);
            font-size: var(--sparky-font-size-14);
            font-weight: var(--sparky-font-weight-bold);

            .sparky-c-icon {
                display: inline;
                vertical-align: bottom;
                color: var(--sparky-theme-color-primary-foreground);
            }
        }

        > .category__link--shopall {
            color: var(--sparky-theme-color-primary-foreground);
            font-size: var(--sparky-font-size-16);
            text-align: left;
            text-transform: capitalize;

            .sparky-c-icon {
                display: inline;
                vertical-align: middle;
            }
        }
    }
}
