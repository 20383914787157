@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';
@import '~common-next/styles/mixins';

.product-carousel-container {
    width: 100%;
    padding: 0;
    margin-bottom: size(4);

    .sparky-c-section {
        margin: 0;
        padding: 0 size(2);
    }

    // This is a temporary workaround to fix from velocity app.
    // The permanent fix will be worked on as a part of HCP-680
    .sparky-c-horizontal-overflow-carousel__inner {
        overflow-x: auto;
    }

    @media (min-width: $sparky-bp-md) {
        .sparky-c-section {
            padding: 0 size(4);
        }
    }

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);

        .sparky-c-section {
            overflow: hidden;
            border-radius: var(--sparky-border-radius-xl);
            padding: 0 size(4);
        }
    }
}

.product-carousel-container--campaign {
    .sparky-c-section {
        margin: 0;
        padding: size(3) size(2);
    }

    .sparky-c-card {
        padding: size(1);
    }

    @media (min-width: $sparky-bp-md) {
        .sparky-c-section {
            padding: size(3) size(4);
        }

        .sparky-c-card {
            padding: size(2);
        }
    }

    @media (min-width: $sparky-bp-lg) {
        padding-inline: size(4);

        .sparky-c-section {
            overflow: hidden;
            border-radius: var(--sparky-border-radius-xl);
            padding: size(4);
        }
    }
}

.product-carousel {
    .sparky-c-section__title-after {
        &:not(&--below-description) {
            text-align: right;
            margin-left: size(3);
        }

        &--below-description {
            margin-top: size(0.5);
        }
    }

    .sparky-c-product-card__badge {
        position: absolute;
    }

    .sparky-c-heading {
        @include truncateText(2);
    }

    &__carousel {
        &--disable-arrows {
            .sparky-c-horizontal-overflow-carousel__controls {
                display: none;
            }
        }
    }

    .sparky-c-product-card__meta {
        min-height: size(2);
    }

    &--storytelling .sparky-c-section__body {
        @media all and (min-width: $sparky-bp-lg) {
            display: grid;
            grid-template-columns: to-rem(392px) minmax(to-rem(400px), 100%);
            grid-gap: to-rem(32px);
        }

        .product-carousel__card {
            margin-bottom: to-rem(24px);
        }
    }

    &--restyled {
        .sparky-c-section {
            &__title {
                font-size: var(--sparky-font-size-32) !important;
                margin-right: 0;

                @media (min-width: $sparky-bp-lg) {
                    font-size: var(--sparky-font-size-40) !important;
                }
            }

            &__description {
                margin-top: size(0.5);
            }
        }

        .sparky-c-text-link {
            font-size: var(--sparky-font-size-14);
        }

        .sparky-c-heading-title {
            text-align: center;
        }
    }
}
