@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';

.autocomplete-panel {
    background-color: var(--sparky-theme-color-body-background);
    box-shadow: 0 to-rem(3px) to-rem(6px) #00000029;
    border-radius: 0 0 to-rem(8px) to-rem(8px);
    font-family: var(--sparky-font-family-primary);
    margin-top: to-rem(1px);
    opacity: 1;
    position: absolute;
    z-index: var(--sparky-z-index-500);
    width: 100%;

    .autocomplete-panel-layout {
        font-family: Arial, Helvetica, sans-serif;
        padding: to-rem(19px) 0;
        padding-bottom: to-rem(8px);

        .autocomplete-source-header {
            margin: 0;
            padding-left: to-rem(16px);
            width: 100%;
            border: 0;
            color: var(--sparky-color-neutral-black);
            font-family: var(--legacy-header-font-family);
            font-weight: var(--sparky-font-weight-bold) !important;
            text-align: left;
            margin-bottom: to-rem(4px);
            line-height: to-rem(17px);
        }

        .autocomplete-source:not(:first-child) {
            .autocomplete-source-header {
                margin-top: to-rem(18px);
                margin-bottom: to-rem(9px);
            }
        }

        .autocomplete-list {
            margin: 0;
            padding: 0;

            .autocomplete-list-item {
                display: flex;
                min-height: to-rem(40px);
                list-style-type: none;

                &.autocomplete-product-suggestions {
                    height: to-rem(64px);

                    &:not(:last-child) {
                        margin-bottom: to-rem(16px);
                    }

                    .autocomplete-list-item {
                        &__product {
                            text-align: left;
                            padding-left: to-rem(16px);
                        }

                        &__main {
                            margin-left: to-rem(16px);
                            max-width: to-rem(282px);
                            font-size: var(--sparky-font-size-14);
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                        }

                        &__product-name {
                            max-height: to-rem(38px);
                            text-overflow: ellipsis;
                            line-height: to-rem(20px);
                            overflow: hidden;
                            display: -webkit-box;
                            /* stylelint-disable-next-line property-no-unknown */
                            line-clamp: 2;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        &__price {
                            font-weight: var(--sparky-font-weight-bold);
                        }
                    }
                }

                .sparky-c-icon {
                    flex-shrink: 0;
                }

                &__product {
                    display: flex;
                    flex-direction: row;
                }

                &__main {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                &__category {
                    margin-left: to-rem(10px);
                }

                &__name {
                    margin-left: to-rem(9px);
                    padding: to-rem(10px) 0;
                }

                &__button {
                    color: var(--sparky-color-neutral-900);
                    background: none;
                    border: none;
                    padding: 0;
                    font: inherit;
                    cursor: pointer;
                    width: 100%;
                    height: auto;

                    &:focus {
                        background-color: #ccc;
                        outline: 0;
                    }

                    &-content {
                        padding-left: to-rem(17px);
                        padding-right: to-rem(19px);
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        height: 100%;
                    }
                }

                &__icon {
                    height: to-rem(18px);
                    width: to-rem(18px);

                    &__arrow {
                        height: to-rem(14px);
                        width: to-rem(14px);
                    }
                }
            }

            .highlighted-text {
                font-weight: bold;
                background-color: transparent;
            }

            .normal-text {
                background-color: transparent;
            }
        }
    }
}

.legacy-header__search {
    .autocomplete-list-item__product {
        padding-left: 0 !important;
    }
}
