@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.campaign-hero-md {
    display: grid;
    align-items: start;
    color: white;

    .sparky-c-heading {
        width: 100%;
        grid-area: 1 / 1;
        font-weight: var(--sparky-font-weight-bold);
        font-size: size(8);
        line-height: size(9);
        padding: size(3) size(2);
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .sparky-c-picture {
        grid-area: 1 / 1;
    }

    .sparky-c-image {
        height: 375px;
        object-fit: cover;
    }

    &__grey {
        color: #131313;
    }

    &__red {
        color: #dd2834;
    }

    @media (min-width: $sparky-bp-md) {
        align-items: center;

        .sparky-c-heading {
            padding: 0 size(4);
            max-width: 80%;
        }

        .sparky-c-image {
            height: 224px;
        }
    }

    @media (min-width: $sparky-bp-lg) {
        .sparky-c-heading {
            font-size: size(10);
            line-height: size(11);
            padding: 0 size(8);
        }

        .sparky-c-image {
            height: 216px;
        }
    }
}
