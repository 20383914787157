@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';

@mixin category-item-box {
    border-bottom: 1px solid var(--sparky-color-neutral-100);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 67px;
}

@mixin category-item-font {
    font-size: 21px;
    font-weight: var(--sparky-font-weight-bold);
    font-family: var(--legacy-header-font-family);
}

.sparky-body-is-disabled {
    overflow: hidden !important;
}

.legacy-header__banner-head {
    .sparky-c-button--lg {
        .sparky-c-button__icon {
            height: to-rem(18px);
            width: to-rem(25px);
        }
    }
}

.legacy-drawer {
    .drawer-back-button {
        display: flex;
    }

    .category__button--back {
        padding-left: size(2.2);
        justify-content: flex-start;
        color: var(--sparky-theme-color-primary-foreground);
        font-family: var(--legacy-header-font-family);
        font-size: var(--sparky-font-size-16);

        .sparky-c-icon--chevron-left {
            width: 16px;
            margin-right: 10px;
        }
    }

    &.sparky-c-drawer {
        z-index: 1000;
    }

    .sparky-c-drawer {
        &__window {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100vw;
        }

        &__header {
            border-bottom-width: 2px;
            height: 68px;
            padding-left: 0;
            padding-right: 24px;

            .sparky-c-logo {
                position: relative;
                top: -3px;
                max-width: unset;
                width: 104px;
            }

            .sparky-c-drawer__close-button {
                height: 50px;
                width: 50px;
                background: none;

                svg {
                    height: size(3);
                    width: size(3);
                }
            }

            &-content {
                display: flex;
                justify-content: space-between;
                margin-left: unset;
                width: 100%;
                align-items: center;

                .header-drawer {
                    &__account {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        top: 2px;

                        svg {
                            color: var(--sparky-color-neutral-900);
                        }
                    }

                    &__cart {
                        width: 80px;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: row;
                        position: relative;
                        top: 1px;

                        .legacy-mini-cart img {
                            width: size(3.25); // 26px;
                            height: size(3.13); // 25px;
                        }

                        svg {
                            color: var(--sparky-color-neutral-900);
                        }
                    }
                }
            }
        }

        &__body {
            padding: 0;

            .header-drawer--top {
                background-color: var(--sparky-color-brand-blue-50);
                border-bottom: 0;
                font-size: var(--sparky-font-size-18);
                height: to-rem(50px);
            }

            .menu-category {
                display: flex;
                flex-direction: column;
                font-family: var(--legacy-header-font-family);
                list-style: none;
                margin: 0;
                padding: 0;
                width: 100%;

                .category {
                    padding: 0;
                    width: 90%;
                    height: to-rem(67px);
                    margin-left: 5%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid var(--sparky-color-neutral-100);

                    @include category-item-font;

                    &--thin {
                        font-weight: var(--sparky-font-weight-regular);
                    }

                    &--secondary-legacy {
                        background-color: var(--sparky-color-neutral-50);
                    }

                    .sparky-c-icon--chevron-right {
                        width: 20px;
                        height: 20px;
                        color: var(--sparky-theme-color-primary-foreground);
                    }

                    &__link {
                        width: 100%;
                        padding: 0;
                        text-decoration: none;
                        color: var(--sparky-color-neutral-900);
                        font-weight: var(--sparky-font-weight-bold);
                    }

                    &__button {
                        align-items: center;
                        background: none;
                        border: none;
                        cursor: pointer;
                        color: var(--sparky-color-neutral-900);
                        font-weight: var(--sparky-font-weight-bold);
                        display: flex;
                        justify-content: space-between;
                        padding: 0;
                        width: 100%;

                        &--back {
                            padding: 0;
                        }
                    }
                }
            }

            .bottom-nav-links {
                display: none;
                gap: 0;
                margin: 0;
                padding: 0;
                font-family: var(--legacy-header-font-family);

                .sparky-c-link-list__item {
                    background-color: var(--sparky-color-neutral-50);
                    margin: 0 !important;
                    padding-left: size(2.4);

                    @include category-item-box;

                    svg {
                        display: none;
                    }

                    .sparky-c-link-list__text {
                        @include category-item-font;
                    }
                }

                .bottom-login-link {
                    background-color: var(--sparky-color-neutral-50);
                    padding-left: size(2.4);
                    padding-right: size(2.4);

                    @include category-item-box;

                    align-items: center;

                    .login-anchor {
                        @include category-item-font;

                        color: var(--sparky-color-neutral-900);
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        text-decoration: none;

                        .login-btn {
                            color: var(--sparky-theme-color-primary-foreground);
                        }
                    }

                    .logout-anchor {
                        @include category-item-font;

                        text-decoration: none;
                        color: #999;
                        font-weight: var(--sparky-font-weight-normal);

                        .logout-btn {
                            @include category-item-font;

                            margin-left: 5px;
                            color: var(--sparky-color-neutral-900);
                            font-weight: var(--sparky-font-weight-bold);
                        }
                    }
                }

                &--lvl1 {
                    display: flex;
                }
            }
        }
    }

    .mobile-nav-footer {
        background-color: var(--sparky-theme-color-primary-foreground);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;

        .customer-care-number-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: var(--legacy-header-font-family);
            color: white;
            text-decoration: none;
            font-size: var(--sparky-font-size-20);

            .circled-phone {
                width: 30px;
                height: 30px;
                border: solid 2px var(--sparky-color-neutral-white);
                border-radius: 100%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            .customer-care-number {
                margin-left: 10px;
            }
        }
    }
}
