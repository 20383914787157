@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.campaign-banner-sm {
    width: 100%;
    margin-bottom: size(4);
    padding: 0;

    .sparky-c-heading {
        grid-area: 1 / 1;
        font-size: size(4);
        line-height: size(5);
        text-align: center;
        padding: 0 size(4);
        font-weight: var(--sparky-font-weight-bold);
    }

    .sparky-c-picture {
        grid-area: 1 / 1;
    }

    .sparky-c-image {
        height: 128px;
        object-fit: cover;
    }

    &__content {
        width: 100%;
        display: grid;
        place-items: center;
        height: 128px;
    }

    &__white {
        color: var(--sparky-color-neutral-white);
    }

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);
        padding: 0 size(4);

        .sparky-c-heading {
            font-size: size(5);
            line-height: size(6);
        }

        &__content {
            border-radius: var(--sparky-border-radius-xl);
            overflow: hidden;
        }
    }
}
