@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.sign-in-drawer {
    z-index: var(--sparky-z-index-top);

    .sparky-c-drawer__header {
        padding: size(2) !important;

        #drawer-heading-1 {
            font-size: var(--sparky-font-size-20) !important;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: size(4);

        &--header {
            padding-bottom: size(0.5) !important;

            .sparky-c-heading {
                font-size: var(--sparky-font-size-16) !important;
            }
        }
    }
}
