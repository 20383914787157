@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.sitewide-promo-carousel {
    width: 100%;
    min-height: to-rem(34px);
    overflow: hidden;
    white-space: nowrap;

    .sparky-c-horizontal-overflow-carousel-wrapper {
        width: 100%;
    }

    .sparky-c-horizontal-overflow-carousel__controls {
        display: none;
    }

    &__item {
        width: 100%;
    }

    .sparky-c-horizontal-overflow-carousel__list {
        padding: 0;
        background-color: var(--sparky-color-brand-red-700);
    }

    .sitewide-promo.sparky-c-banner {
        padding: 0;

        .sparky-c-text-passage__inner {
            p {
                margin-left: to-rem(48px);
                margin-right: to-rem(48px);
                text-wrap: initial;

                > a {
                    width: 100%;
                    padding: to-rem(7px) 0;
                    display: inline-block;
                    color: var(--sparky-color-neutral-white);
                    font-weight: var(--sparky-font-weight-regular);
                    font-size: var(--sparky-font-size-14);
                    text-wrap: initial;
                }
            }
        }
    }
}
