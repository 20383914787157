@import '~common-next/styles/functions';

.store-drawer {
    .sparky-c-drawer__window {
        height: 100%;
    }

    .sparky-c-icon--x {
        height: to-rem(16px) !important;
        width: to-rem(16px) !important;
    }

    .sparky-c-icon--search {
        height: to-rem(18px) !important;
        width: to-rem(18px) !important;
    }

    .sparky-c-button--primary {
        height: to-rem(48px);
    }

    .c-store-search-drawer__use-location {
        .sparky-c-button__text {
            line-height: to-rem(20px);
        }
    }

    .c-store-listing-card__heading {
        .sparky-c-badge--info {
            .sparky-c-badge__text {
                line-height: to-rem(16px);
            }
        }
    }

    &.pdp-page .c-store-listing-card__open-time {
        color: var(--sparky-theme-color-body-foreground);

        &.closed {
            color: var(--sparky-theme-color-body-foreground);
        }
    }

    .c-store-search-drawer__search {
        .c-store-search-drawer__input {
            height: to-rem(40px);
            font-size: var(--sparky-font-size-16);
        }
    }

    .sparky-c-button__text {
        font-weight: var(--sparky-font-weight-regular);
        font-size: var(--sparky-font-size-14);
    }
}
