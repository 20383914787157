.legacy-topnav {
    &__container {
        display: flex;
        align-items: center;
        font-family: var(--legacy-header-font-family) !important;
        font-size: var(--sparky-font-size-16) !important;
    }

    &__link {
        font-family: var(--legacy-header-font-family) !important;
        font-size: var(--sparky-font-size-16) !important;
    }

    &__divider {
        display: inline-block;
        margin: 0 15px;
    }

    &__container--center .lives-saved .lives-saved__text .link-wrapper {
        color: var(--sparky-color-neutral-black);
        font-size: 0.9rem !important;
        font-family: var(--legacy-header-font-family) !important;
        font-weight: var(--sparky-font-weight-bold) !important;

        span {
            color: var(--sparky-color-neutral-black);
        }

        svg {
            display: none;
        }
    }
}
