@import '@petsmart-ui/sparky/dist/scss/breakpoints.scss';
@import '@petsmart-ui/sparky/dist/scss/mixins.scss';

/** ------------------------------------
 * breakpoints
 * ---------------------------------- */

$desktop-min: $sparky-bp-lg; // 960px // desktop
$desktop-max: calc($sparky-bp-xl - 1px); // 1199px // desktop

$desktop-large-min: $sparky-bp-xl; // 1200px // desktop
$desktop-large-mid: $sparky-bp-xxl; // 1400px // desktop
$desktop-large-max: 1600px; // 1600px // desktop

$mobile-max: calc($sparky-bp-md - 1px); // 767px // tablet
$mobile-xs-max: calc($sparky-bp-sm - 1px); // 374px // mobile

$tablet-min: $sparky-bp-md; // 768px // tablet
$tablet-max: calc($sparky-bp-lg - 1px); // 959px // desktop

/** ------------------------------------
 * colors
 * ---------------------------------- */

$heading-gray: #131313;

$section-background-grey: #f7f7f7;
$section-border-grey: #e3e3e3;
$section-border: 1px solid $section-border-grey;

$text-dark-grey: var(--sparky-theme-color-body-foreground);

$border-medium-grey: #acacac;

$transparent-50: rgb(0 0 0 / 50%);

$white: #fff;

/** ------------------------------------
 * mixins
 * ---------------------------------- */

@mixin hide-scroll-bar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin grid-gap-carousel {
    grid-gap: calc(var(--sparky-size-base-unit));

    @media all and (min-width: ($tablet-min)) {
        grid-gap: calc(var(--sparky-size-base-unit) * 2);
    }

    @media all and (min-width: ($desktop-large-min)) {
        grid-gap: calc(var(--sparky-size-base-unit) * 4);
    }
}

@mixin set-width-and-height($value) {
    height: $value;
    width: $value;
}

@mixin set-max-width-and-max-height($value) {
    max-height: $value;
    max-width: $value;
}

@mixin close-button-colors {
    background-color: var(--sparky-color-neutral-50);
    color: var(--sparky-color-neutral-black);
}

@mixin set-top-and-bottom-padding($value) {
    padding-top: $value;
    padding-bottom: $value;
}

@mixin set-top-and-bottom-margin($value) {
    margin-top: $value;
    margin-bottom: $value;
}

@mixin align-and-justify-items($value) {
    align-items: $value;
    justify-items: $value;
}

@mixin align-and-justify-content($value) {
    align-content: $value;
    justify-content: $value;
}

@mixin create-circle {
    border-radius: 50%;
}

@mixin override-sparky-heading-size {
    // Sparky is using !important; need to override to match mocks
    .sparky-c-heading.sparky-c-heading--title {
        font-size: var(--sparky-font-size-20) !important;
    }

    @media all and (min-width: $desktop-min) {
        .sparky-c-heading.sparky-c-heading--title {
            font-size: var(--sparky-font-size-24) !important;
        }
    }
}

@mixin alert-banner-container-styles($isFeatured: true) {
    #alert-banner-container {
        .sparky-c-alert__body .sparky-c-heading--body-bold {
            font-size: 14px !important;
            line-height: 18px !important;

            .sparky-c-text-passage {
                padding-right: 0;
            }
        }

        .sparky-c-alert {
            @if $isFeatured {
                margin: 0 calc(var(--sparky-size-base-unit) * 2) $margin-md;

                @media all and (width >= 560px) {
                    margin: 0 calc(var(--sparky-size-base-unit) * 4.125)
                        $margin-md;
                }

                @media all and (min-width: $desktop-min) {
                    width: 100%;
                    margin: $margin-md auto;
                    white-space: nowrap;
                }
            } @else {
                margin: 0 auto;
                width: 344px;

                @media all and (min-width: $tablet-min) {
                    width: 710px;
                }

                @media all and (min-width: $desktop-min) {
                    margin-bottom: -16px;
                }

                @media all and (min-width: $desktop-large-min) {
                    white-space: nowrap;
                    width: 100%;
                }
            }
        }
    }
}

/** ------------------------------------
 * sizes
 * ---------------------------------- */

$offer-card-button-height: 32px;
$offer-card-button-height-circle: 40px;

$offer-grid-gap-mobile: calc(var(--sparky-size-base-unit) * 2);
$offer-grid-gap-tablet: calc(var(--sparky-size-base-unit) * 3);
$offer-grid-gap-desktop: calc(var(--sparky-size-base-unit) * 4);

$offer-card-height: 176px;
$offer-card-width-mobile: 344px;

$offer-card-height-featured: 320px;

$offer-card-height-large-mobile: 370px;

$offer-card-width-large-desktop: calc((100% - $offer-grid-gap-desktop) / 2);
$offer-card-width-small-desktop: calc(
    (100% - ($offer-grid-gap-desktop * 3)) / 4
);

$offer-image-height-featured: 123px;
$offer-image-width-featured: 286px;
$offer-image-ratio-featured: calc(
    $offer-image-width-featured / $offer-image-height-featured
);

$offer-image-height-large-desktop: $offer-card-height;
$offer-image-width-large-desktop: 312px;

$offer-image-height-large-mobile: 194px;
$offer-image-width-large-mobile: $offer-card-width-mobile;

$offer-image-height-small: 80px;
$offer-image-width-small: $offer-image-height-small;

$offer-card-content-height: calc(
    $offer-card-height - calc(var(--sparky-size-base-unit) * 2) -
        $offer-card-button-height-circle - $offer-grid-gap-desktop
);
$offer-card-content-height-featured: calc(
    $offer-card-height-featured - $offer-image-height-featured - calc(
            (var(--sparky-size-base-unit) * 2) * 5
        ) - $offer-card-button-height
);

/** ------------------------------------
 * margins & padding
 * ---------------------------------- */

$margin-zero: 0;
$margin-xs: 4px;
$margin-sm: 8px;
$margin-md: 16px;
$margin-lg: 24px;
$margin-xl: 32px;
$margin-xxl: 64px;

$padding-zero: 0;
$padding-xxs: 2px;
$padding-xs: 4px;
$padding-sm: 8px;
$padding-md: 16px;
$padding-lg: 24px;
$padding-xl: 32px;
$padding-xxl: 64px;

/** ------------------------------------
 * z-index
 * ---------------------------------- */

$z-index-sparky-override-999: 999 !important;
$z-index-react-joyride-10000: 10000;
$z-index-react-joyride-override-10001: 10001;

/** ------------------------------------
 * height & width
 * ---------------------------------- */

$full-width-percentage: 100%;
$full-width-mobile: 375px;

$site-min-width-mobile: 360px;

$loading-section-height: 400px;
$loading-section-height-mobile: 250px;

$create-account-width-desktop: 392px;

$loyalty-feature-max-width: 1134px;
$loyalty-feature-body-margin: 138px;
$loyalty-btn-width: 144px;
$loyalty-btn-height: 40px;
$loyalty-charity-img-small: 80px;
$loyalty-charity-img-large: 128px;

/** ------------------------------------
 * grids
 * ---------------------------------- */

$gap-zero: 0;
$gap-xs: 4px;
$gap-sm: 8px;
$gap-md: 16px;
$gap-lg: 24px;
$gap-xl: 32px;
