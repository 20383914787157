.promo-card {
    text-decoration: none;
    color: var(--sparky-color-neutral-900);

    .sparky-c-card__header {
        position: relative;
        margin-bottom: var(--sparky-size-base-unit);
    }

    .sparky-c-card,
    .sparky-c-card__body {
        &:focus,
        &:focus-within {
            outline: 2px solid var(--sparky-theme-color-focus-ring);
            border-radius: 0;
        }
    }

    &.nolink {
        .sparky-c-card,
        .sparky-c-card__body {
            &:focus,
            &:focus-within {
                outline: 0;
            }
        }
    }

    img {
        width: 100%;
        border-radius: var(--sparky-theme-border-radius);
    }
}
