@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.nav-card {
    display: grid;
    padding: size(2);
    overflow: hidden;
    align-content: space-between;
    border-radius: var(--sparky-border-radius-xl);
    text-decoration: none;
    min-height: 168px;
    color: inherit;
    gap: size(1);

    .sparky-c-image {
        width: 56px;
        height: 56px;
        object-fit: cover;
        border-radius: 50%;
    }

    .sparky-c-heading {
        font-size: 20px;
        line-height: 28px;
    }

    @media (min-width: $sparky-bp-md) {
        height: 180px;

        .sparky-c-heading {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 84px;
        }
    }

    @media (min-width: $sparky-bp-lg) {
        .sparky-c-heading {
            -webkit-line-clamp: 2;
            max-height: 56px;
        }
    }
}
