@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.visual-navigation-container {
    overflow: hidden;
    margin: auto;

    @media all and (min-width: $sparky-bp-lg) {
        width: 100%;
    }
}

.tile6up-visual-nav {
    // tsx adds "xl-even-spacing" when list has less than 6 items

    &.xl-even-spacing {
        .sparky-c-horizontal-overflow-carousel__list {
            display: flex;

            // list width on markup shrinks to fit the items
            // but we need it to span the whole page
            // in order to space the items evenly on the page
            // when number of items is less than 6
            width: 100% !important;

            .sparky-c-horizontal-overflow-carousel__item {
                display: inline-flex;
                flex: 1;
                justify-content: space-evenly;
            }
        }
    }
}
