@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.lives-saved {
    display: none;

    @media all and (min-width: $sparky-bp-xl) {
        display: block;
    }

    &--footer {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 2px solid var(--sparky-theme-color-neutral-subtle-border);
        border-bottom: none;
        padding: 2.2rem 0;
    }

    &__text {
        color: var(--sparky-color-neutral-900);
        font-family: var(--sparky-font-family-primary);
        text-decoration: none;
        font-size: var(--sparky-font-size-14);
        line-height: var(--sparky-line-height-xxxl);
    }

    &__text:hover {
        text-decoration: underline;
    }

    &__text--strong {
        color: var(--sparky-color-neutral-800);
        font-size: var(--sparky-font-size-16);
    }

    .link-wrapper {
        display: flex;
    }

    @media all and (min-width: $sparky-bp-sm-2) {
        &--footer {
            border-top: 1px solid
                var(--sparky-theme-color-neutral-subtle-border);
            border-bottom: 1px solid
                var(--sparky-theme-color-neutral-subtle-border);
        }
    }

    @media all and (min-width: $sparky-bp-sm-2) {
        &__text--strong {
            font-size: var(--sparky-font-size-24);
        }
    }
}
