@import '~common-next/styles/functions';

.two-line-snackbar {
    font-family: var(--sparky-font-family-primary);
    background: #333;
    width: 343px;
    margin: 0 auto;
    border-radius: var(--sparky-theme-border-radius);
    padding: 14px 16px;
    font-size: var(--sparky-font-size-14);
    align-items: center;
    height: 64px;
    display: flex;
    justify-content: space-between;

    &__text {
        display: flex;
        flex-direction: column;
        color: #fff;
    }

    &__action {
        font-weight: 700;
        color: #56b3ff;

        .sparky-c-button--link {
            background: transparent;
            outline: none;
        }
    }
}
