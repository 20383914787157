@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.drawer-back-button {
    display: flex;
}

.drawer.sparky-c-drawer {
    .sparky-c-button__text {
        font-size: var(--sparky-font-size-18);
        color: var(--sparky-color-neutral-900);
        font-weight: var(--sparky-font-weight-bold);
    }

    .sparky-c-drawer {
        &__header {
            height: to-rem(64px);
            padding: to-rem(24px) to-rem(16px) to-rem(24px) to-rem(8px);

            .sparky-c-logo {
                max-width: unset;
                width: to-rem(104px);
            }

            @media all and (min-width: $sparky-bp-md) {
                padding-left: to-rem(32px);
            }
        }

        &__close-button {
            .sparky-c-icon {
                width: to-rem(20px);
                height: to-rem(20px);
            }

            background-color: var(
                --sparky-theme-color-neutral-container-default
            );
        }

        &__window {
            width: 90%;
        }

        &__body.drawer-body {
            padding: 0 0 to-rem(72px);

            .menu-category {
                list-style: none;
                display: flex;
                flex-direction: column;
                font-family: euclid, sans-serif;
                padding: 0;
                margin: 0;
                width: 100%;

                // Images (grid)
                &--lvl2:has(> .category--image) {
                    justify-content: center;
                    flex-flow: row wrap;
                    padding-bottom: size(2);
                    gap: size(2);
                    border-bottom: 1px solid var(--sparky-color-neutral-100);
                }
            }

            .link-list {
                gap: size(3);
            }
        }
    }
}

.category,
.header-drawer {
    border-bottom: 1px solid var(--sparky-color-neutral-100);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: var(--sparky-font-size-16);
    font-weight: var(--sparky-font-weight-bold);
    height: size(7); // 56px
    width: 100%;
    font-family: euclid, sans-serif;
    color: var(--sparky-color-neutral-900);

    &--top {
        border-bottom: 0;
        font-size: var(--sparky-font-size-18);
    }

    &--thin {
        font-weight: var(--sparky-font-weight-regular);
    }

    &.highlighted {
        .category__link,
        span {
            color: var(--sparky-color-brand-red-700);
        }
    }

    // tablet only
    @media all and (min-width: $sparky-bp-md) {
        padding-left: size(2);

        &--top {
            padding: 0;
        }
    }

    .sparky-c-icon--chevron-right {
        width: size(2.5); // 20px
        height: size(2.5); // 20px
    }

    &__link {
        align-self: center;
        width: 100%;
        padding: 0 size(2) 0 size(2.25);
        text-decoration: none;
        color: var(--sparky-color-neutral-900);

        &--shop-all {
            color: var(--sparky-theme-color-primary-default);
        }
    }

    &__button {
        padding: 0 size(2) 0 size(2.25);
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        font: inherit;
        justify-content: space-between;
        width: 100%;
        color: var(--sparky-color-neutral-900);
        gap: 5px;

        &--back {
            padding: 0;
        }
    }

    // Images
    .menu-category--lvl2 > &--image {
        border: 0;
        display: inline-block;
        padding: 0;
        width: size(17.5); // 140px
        height: size(10.125); // 81px
        text-align: center;
        flex-basis: calc(50% - size(2));

        @media all and (min-width: $sparky-bp-sm-2) {
            flex-basis: calc(33% - to-rem(16px));
        }

        @media all and (min-width: $sparky-bp-md) {
            width: size(27.5); // 220px
            height: size(15.875); // 127px
        }

        .category__link {
            display: inline-block;
            padding: size(2) size(3);
            width: size(17.5); // 140px
            height: size(10.125); // 81px
            border-radius: var(--sparky-border-radius-lg);
            background: var(--sparky-color-neutral-50);

            @media all and (min-width: $sparky-bp-md) {
                width: size(27.5); // 220px
                height: size(15.875); // 127px
            }

            > picture > img {
                display: block;
                margin: 0 auto;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}
