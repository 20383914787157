@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.campaign-hero-container {
    width: 100%;
    margin-bottom: size(4);
    padding: 0;

    @media (min-width: $sparky-bp-lg) {
        padding-inline: size(4);
        margin-bottom: size(8);

        & > * {
            border-radius: var(--sparky-border-radius-xl);
            overflow: hidden;
        }
    }
}
