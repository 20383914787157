@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.tile4-container {
    margin-left: 0;
    margin-right: 0;

    @media (min-width: $sparky-bp-lg) {
        margin-right: auto;
        margin-left: auto;
    }
}

.tile4-links--mobile {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(2, 1fr);

    &--spread {
        grid-template-rows: repeat(4, 1fr);
    }

    &__link {
        margin-bottom: size(4);
        display: block;
        width: 100%;
        text-align: center;
    }

    @media (min-width: $sparky-bp-md) {
        display: none;
    }
}

.tile4-links {
    display: none;

    @media (min-width: $sparky-bp-md) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-row: auto;
        column-gap: size(2);
    }

    @media (min-width: $sparky-bp-lg) {
        grid-template-columns: repeat(4, minmax(210px, 286px));
        grid-template-rows: none;
        grid-column-gap: size(4);
    }
}
