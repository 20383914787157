@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';
@import '~common-next/styles/mixins';

.tile6up-container {
    width: 100%;

    .sparky-c-section {
        margin-bottom: size(4);

        @media (min-width: $sparky-bp-lg) {
            margin-bottom: size(8);
        }
    }
}

.tile6up {
    @include divider;

    .sparky-c-button--circle {
        top: 50%;
    }

    .sparky-c-horizontal-overflow-carousel__button-prev {
        left: to-rem(10px);
    }

    .sparky-c-horizontal-overflow-carousel__button-next {
        right: to-rem(10px);
    }

    .sparky-c-horizontal-overflow-carousel-wrapper {
        overflow-x: hidden !important;
    }

    .sparky-c-section__title-after {
        &:not(&--below-description) {
            text-align: right;
            margin-left: size(3);
        }

        &--below-description {
            margin-top: size(0.5);
        }

        @media (min-width: $sparky-bp-md) {
            text-align: left;
        }
    }

    &-shoppable {
        .sparky-c-tile picture {
            max-width: 272px;

            @media (min-width: $sparky-bp-lg) {
                max-width: 360px;
            }
        }
    }

    &.variant-tips {
        .sparky-c-section__header {
            margin-bottom: size(2);
        }

        &.no-tile-header {
            margin-top: size(4);
        }
    }

    &.title-center {
        .sparky-c-section__title {
            margin-right: 0;
            text-align: center;

            &-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: size(0.5);
            }

            &-after {
                display: grid;
                gap: size(0.5);
                text-align: center;
            }
        }
    }

    &--restyled {
        .sparky-c-section {
            &__title {
                margin-right: 0;
                line-height: size(6) !important;
                padding-top: 0;
                font-size: var(--sparky-font-size-32) !important;

                @media (min-width: $sparky-bp-lg) {
                    font-size: var(--sparky-font-size-40) !important;
                }
            }

            &__description {
                margin-top: size(0.5);
            }
        }

        .sparky-c-text-link {
            font-size: var(--sparky-font-size-14);
            line-height: var(--sparky-line-height-md);
        }

        .sparky-c-heading--title {
            text-align: center;
        }
    }
}
