@import '@petsmart-ui/sparky/dist/scss/functions';

.mainmenu__primary-nav .submenu-container {
    // Reset list style
    .sparky-c-primary-nav__item {
        list-style-type: none;
    }

    // Remove button/a as we only need the list
    .sparky-c-primary-nav__link {
        display: none;
    }

    // Layout Type : Text
    .submenu__layout--text .submenu-container__list {
        display: block;
        columns: 3;

        > .sparky-c-primary-nav__item {
            padding-bottom: size(4);
        }

        .sparky-c-primary-nav__item {
            page-break-inside: avoid;
            break-inside: avoid-column;
        }
    }

    // Category type : Default / Links
    .sparky-c-text-link {
        color: var(--sparky-theme-color-body-foreground);
    }

    .sparky-c-primary-nav__link + .sparky-c-primary-nav__item-panel {
        .sparky-c-link-list__link:first-child {
            font-weight: var(--sparky-font-weight-bold);
        }
    }

    // Layout Type : Image
    .submenu__layout--image .submenu-container__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: size(4);
    }

    // Category type : Image
    .category__item--image {
        .sparky-c-text-link {
            display: block;
            padding: size(2) size(3);
            width: size(22.5); // 180px
            height: size(13); // 104px
            background: var(--sparky-color-neutral-50);
            border-radius: var(--sparky-border-radius-lg);
            color: var(--sparky-theme-color-body-foreground);
            text-align: center;

            > img {
                display: block;
                margin: 0 auto;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    // Layout Type : Tabs
    .submenu__layout--tabs .submenu-container__list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-flow: row wrap;

        > [class^='sparky-c-tabs_'] {
            padding: 0;
        }
    }

    .submenu-container__list > .sparky-c-tabs__header {
        width: size(22.5); // 180px
        margin-right: -1px;
        border-bottom: 0;
        border-right: var(--sparky-theme-border-width) solid
            var(--sparky-theme-color-neutral-subtle-border);

        .sparky-c-tabs__list {
            display: flex;
            flex-direction: column;
        }

        .sparky-c-tabs__item {
            margin: 0;

            &.sparky-is-active,
            &:hover {
                background-color: var(
                    --sparky-theme-color-neutral-subtle-border
                );
            }
        }

        .sparky-c-tabs__link {
            padding: size(1.75) size(2);
            background-color: transparent;
            box-shadow: none;
            font-size: var(--sparky-font-size-16);
            font-weight: var(--sparky-font-weight-bold);
            line-height: var(--sparky-font-size-24);
            text-align: left;
            text-transform: capitalize;
            outline-offset: -1px;
        }
    }

    .submenu-container__list > .sparky-c-tabs__body {
        flex: auto;
        height: 100%;
        border-left: var(--sparky-theme-border-width) solid
            var(--sparky-theme-color-neutral-subtle-border);

        > .sparky-c-tabs__panel {
            display: flex;
            margin-bottom: size(4);
            gap: size(4);
            height: 100%;

            > .submenu-container__list {
                display: block;
                columns: 3;

                > .sparky-c-primary-nav__item {
                    padding-bottom: size(4);
                }

                .sparky-c-primary-nav__item {
                    page-break-inside: avoid;
                    break-inside: avoid-column;
                }
            }
        }
    }
}
