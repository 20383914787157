@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.faq-store-card {
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: size(3);
    border: 1px solid var(--sparky-theme-color-neutral-subtle-border);
    border-radius: var(--sparky-border-radius-lg);

    &__headline {
        display: block;
        font-weight: bold;
        font-size: var(--sparky-font-size-16);
        line-height: var(--sparky-line-height-md);
        margin-bottom: size(1);
    }

    &__text {
        margin-bottom: auto;
    }

    .sparky-c-text-passage {
        padding-left: 0;
        width: 70%;
    }

    &__badges {
        height: size(3);
        margin-top: size(1);
        display: flex;
        column-gap: size(1);
    }

    &__badge {
        width: fit-content !important;
        height: size(3);
        padding: size(0.625) size(0.75) 0 !important;
        text-align: center;
        border-radius: var(--sparky-border-radius-md);
        background-color: var(--sparky-color-brand-blue-50);
        color: var(--sparky-color-brand-blue-600);

        .sparky-c-text-passage__inner {
            width: fit-content;
        }
    }

    .sparky-c-button {
        width: 100%;
        margin-top: size(2.5);
    }
}

.faq-store-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: size(2);

    @media (min-width: $sparky-bp-md) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: size(3);
    }

    @media (min-width: $sparky-bp-lg) {
        grid-template-columns: repeat(4, minmax(210px, 286px));
        gap: size(4);
    }
}
