@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '~common-next/styles/functions';

.legacy-my-store {
    font-family: var(--legacy-header-font-family);
    background: var(--sparky-color-neutral-white);
    border: to-rem(1px) solid var(--sparky-color-neutral-white);
    width: to-rem(550px);
    padding: to-rem(26px) to-rem(46px) to-rem(33px);
    right: 0;
    z-index: var(--sparky-z-index-400);
    position: absolute;
    overflow: hidden;
    text-align: left;
    border-radius: 0 0 to-rem(5px) to-rem(5px);
    height: auto;

    @media all and (min-width: $sparky-bp-xl) {
        height: auto;
        overflow-y: auto;
        min-height: initial;

        @media screen and (max-height: to-rem(450px)) {
            height: to-rem(200px);
        }

        @media screen and (min-height: to-rem(451px)) and (max-height: to-rem(500px)) {
            height: to-rem(250px);
        }

        @media screen and (min-height: to-rem(501px)) and (max-height: to-rem(550px)) {
            height: to-rem(300px);
        }

        @media screen and (min-height: to-rem(551px)) and (max-height: to-rem(600px)) {
            height: to-rem(350px);
        }

        @media screen and (min-height: to-rem(601px)) and (max-height: to-rem(650px)) {
            height: to-rem(400px);
        }

        @media screen and (min-height: to-rem(651px)) and (max-height: to-rem(700px)) {
            height: to-rem(450px);
        }

        @media screen and (min-height: to-rem(701px)) and (max-height: to-rem(750px)) {
            height: to-rem(500px);
        }
    }

    > .sparky-c-primary-nav__link {
        display: none;
    }

    .my-store-wrapper {
        .store-button-link {
            line-height: 3.5rem;
            font-size: 1.5rem;
            letter-spacing: to-rem(1px);
            font-weight: bold;
        }

        .store-details-wrapper {
            display: flex;
            justify-content: space-between;

            .ratings-reviews-modal-link {
                text-decoration: none;

                &:hover .stars-rating {
                    color: var(--legacy-header-color-loyal-blue);
                }
            }

            .my-store-details {
                .mystore-location-new {
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    font-weight: bold;
                }

                .my-store-details-title {
                    color: var(--sparky-color-neutral-800);
                    font-weight: var(--legacy-header-font-weight-bolder);
                    font-size: to-rem(20px);
                    margin-right: to-rem(20px);
                    line-height: to-rem(26px);
                }

                .stars-rating {
                    float: left;
                    font-weight: var(--legacy-header-font-weight-bolder);
                    font-size: to-rem(16px);
                    line-height: to-rem(19px);
                    margin-right: to-rem(10px);
                    color: var(--legacy-header-color-loyal-blue);
                }

                .total-review-count {
                    font-size: to-rem(10px);
                    margin-left: to-rem(5px);
                    line-height: to-rem(21px);
                    width: to-rem(200px);
                    color: var(--sparky-color-brand-blue-500);
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: var(
                            --sparky-color-brand-blue-500
                        );
                    }
                }

                .star-svg {
                    height: to-rem(16px);
                    width: to-rem(16px);
                    padding-right: to-rem(4px);
                }

                .stars-container {
                    display: flex;
                    flex-direction: row;
                    margin: to-rem(5px) 0;
                    padding: to-rem(1px);
                    position: relative;
                }

                .unrated-stars-container {
                    display: flex;
                    flex-direction: row;
                }

                .rated-stars-container {
                    display: flex;
                    flex-direction: row;
                    position: absolute;
                    left: 0;
                }

                .my-store-details-phone {
                    font-weight: bold;
                }

                p {
                    margin: 0;
                    font-size: to-rem(16px);
                    line-height: to-rem(20.8px);
                }
            }

            .store-details-links-wrapper {
                .store-detail-link {
                    font-size: to-rem(14px);
                    font-weight: var(--sparky-font-weight-regular);
                    font-family: var(--sparky-font-family-primary);
                    background-color: var(--sparky-color-brand-blue-500);
                    padding: 0 to-rem(20px);
                    min-width: to-rem(170px);
                    letter-spacing: to-rem(1px);
                    color: var(--sparky-color-neutral-white);
                    line-height: to-rem(35px);
                    display: block;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                        background-color: var(--legacy-header-color-deep-blue);
                    }
                }

                .store-directions {
                    line-height: to-rem(19px);
                    font-size: to-rem(16px);
                    margin-top: to-rem(25px);
                    font-weight: var(--sparky-font-weight-regular);
                    font-family: var(--sparky-font-family-primary);
                    float: right;
                    color: var(--sparky-color-brand-blue-500);
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                        text-decoration-color: var(
                            --sparky-color-brand-blue-500
                        );
                    }
                }
            }
        }

        .my-store-main-content-wrapper {
            display: flex;
            justify-content: space-between;
        }

        .my-store-themantic-break {
            width: 100%;
            height: to-rem(1px);
            background-color: var(--legacy-header-color-light-gray);
            left: to-rem(-46px);
            padding: 0 to-rem(46px);
            position: relative;
            margin-bottom: to-rem(20px);
            margin-top: to-rem(20px);
            border: 0;
        }

        .store-info-header {
            margin-bottom: to-rem(10px);
            font-size: to-rem(16px);
            font-weight: var(--sparky-font-weight-bold);
            line-height: to-rem(16px);
            margin-top: 0;
        }

        .more-stores-wrapper {
            position: relative;

            .more-stores-link {
                font-size: to-rem(14px);
                padding: to-rem(4px);
                color: var(--sparky-color-neutral-white);
                position: absolute;
                top: to-rem(-11px);
                left: calc(50% - to-rem(95px));
                width: to-rem(190px);
                text-decoration: none;
                font-family: var(--sparky-font-family-primary);
                font-weight: var(--sparky-font-weight-regular);
                line-height: to-rem(35px);
                text-align: center;
                background-color: var(--sparky-color-brand-blue-500);

                &:hover {
                    text-decoration: underline;
                    background-color: var(--legacy-header-color-deep-blue);
                }
            }
        }
    }

    .my-store-services {
        .my-store-services-content {
            line-height: to-rem(30px);
            font-size: to-rem(14px);

            span {
                text-transform: capitalize;
                display: block;
                line-height: to-rem(30px);
            }
        }
    }

    .my-store-hours {
        .my-store-hours-title {
            font-size: 1.1rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        .my-store-hours-content {
            margin-right: 5rem;
        }

        .my-store-hours-content,
        span {
            line-height: 2.5rem;
            font-size: 1.4rem;
        }

        .day-wrapper {
            display: flex;

            .store-days {
                font-weight: bold;
                display: inline-block;
                min-width: to-rem(60px);
                font-size: to-rem(14px);
                text-transform: capitalize;
                line-height: to-rem(26px);
            }

            .store-times {
                font-size: to-rem(12px);
                line-height: to-rem(25px);
                white-space: nowrap;
                text-transform: lowercase;
            }
        }

        .store-hours-disclaimer {
            font-size: to-rem(12px);
            line-height: to-rem(14px);
            max-width: to-rem(210px);
            margin-top: to-rem(15px);

            p {
                margin: 0;
            }

            a {
                color: var(--legacy-header-color-loyal-blue);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    text-decoration-color: var(
                        --legacy-header-color-loyal-blue
                    );
                }
            }
        }
    }
}
