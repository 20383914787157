@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '~common-next/styles/functions';
@import '@petsmart-ui/sparky/dist/scss/functions';

.autocomplete {
    position: relative;
    width: size(52.74);
    height: size(5);
    max-height: size(5);

    .sparky-c-text-input {
        background-color: var(--sparky-color-neutral-50);
        border: 0;

        &::placeholder {
            color: var(--sparky-color-neutral-600);
        }
    }

    &__input {
        border: none;
        background: var(--sparky-theme-color-neutral-container-default) 0% 0%
            no-repeat padding-box;
    }

    &__label {
        color: var(--sparky-color-neutral-600);
    }

    @media all and (max-width: $sparky-bp-md) {
        grid-area: searchbox;
        width: 100%;
    }

    @media all and (min-width: $sparky-bp-md) and (max-width: $sparky-bp-xl) {
        width: 80%;
    }

    &-searchbox {
        display: flex;
        flex-direction: row;
        align-items: center;

        .sparky-c-inline-form {
            width: 100%;
            max-height: to-rem(40px);

            .sparky-c-button {
                position: absolute;
                max-height: inherit;
                right: 0;
                background: transparent;
                border-color: transparent;
                color: var(--sparky-color-neutral-900);

                &:active {
                    background: transparent;
                    border-color: transparent;
                }

                .sparky-c-icon--search {
                    width: size(2.25);
                    height: size(2.25);
                }
            }
        }
    }

    .autocomplete-cancel {
        display: none;
        padding: to-rem(16px);
        color: var(--sparky-color-neutral-900);

        &:hover {
            color: var(--sparky-color-neutral-900);
        }
    }

    &.legacy-autocomplete {
        @media all and (max-width: $sparky-bp-lg) {
            min-width: to-rem(200px);
        }

        .sparky-c-inline-form__close-button {
            right: to-rem(44px) !important;
        }
    }

    &.velocity-autocomplete {
        @media all and (max-width: $sparky-bp-lg) {
            min-width: to-rem(120px);
        }

        // Focused styles
        @media all and (max-width: $sparky-bp-xl) {
            &.autocomplete--open {
                padding: 0;
                margin: 0;
                background-color: var(--sparky-color-neutral-white);
                position: absolute;
                z-index: var(--sparky-z-index-300);
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                max-height: unset;

                .autocomplete-searchbox {
                    padding: 20px 16px 20px 32px;
                    box-shadow: 0 2px 4px #00000014;

                    .sparky-c-inline-form {
                        .sparky-c-button {
                            right: to-rem(96px);
                        }
                    }
                }

                .autocomplete-cancel {
                    display: inline-block;
                }

                .autocomplete-panel {
                    position: static;
                    margin-top: to-rem(10px);
                    box-shadow: none;
                    width: 100%;
                }
            }
        }

        .sparky-c-inline-form__close-button {
            display: none !important;
        }
    }
}
