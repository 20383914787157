@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.tile6up-tips {
    .sparky-c-tile {
        @media all and (min-width: $sparky-bp-lg) {
            margin-left: auto;
            margin-right: auto;
        }

        picture {
            max-width: size(10);
        }

        &__title {
            font-size: var(--sparky-font-size-14);
            text-align: center;

            @media all and (min-width: $sparky-bp-md) {
                margin-top: size(2);
            }
        }
    }

    .sparky-c-text-link {
        cursor: initial;
    }
}
