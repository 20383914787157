@import '@petsmart-ui/sparky/dist/scss/functions';
@import '~common-next/styles/functions';

.layout-content {
    display: contents;
    --page-layout-container-padding-top: #{to-rem(24px)};

    &--default {
        --page-layout-container-padding-top: #{to-rem(24px)};
    }

    .product-details-page {
        padding-top: var(--page-layout-container-padding-top);
    }
}
