@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '~common-next/styles/functions';

.sitewide-promo {
    &.sparky-c-banner {
        background: unset !important;
        padding: to-rem(13px) 0 to-rem(12px);

        @media all and (max-width: $sparky-bp-sm-2) {
            padding: to-rem(12px);
        }

        .sparky-l-linelength-container {
            max-width: unset;
        }

        p {
            line-height: to-rem(20px);
        }

        p > a {
            line-height: inherit;
            margin: 0;
            font-size: var(--sparky-font-size-16);
            font-family: var(--sparky-font-family-primary);
            font-weight: bold;
            color: var(--sparky-theme-color-utility-sale-light-foreground);

            &:hover {
                color: var(--sparky-theme-color-utility-sale-light-foreground);
            }
        }
    }

    + .product-list-page .sparky-l-container {
        .tile6up,
        .feature-banner,
        .product-carousel,
        .promo-banner {
            margin-top: 0 !important;
            padding-top: 0 !important;
        }
    }
}
