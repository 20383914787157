@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.tile4-link-block {
    border: 1px solid var(--sparky-theme-color-neutral-subtle-border);
    border-radius: var(--sparky-border-radius-xl);
    padding-top: size(1.875);
    padding-left: size(2);
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-bottom: size(2);

    &__heading {
        display: flex;
        flex-direction: column;
        margin-left: size(1.5);
        row-gap: size(2);
        margin-bottom: size(1.875);

        .sparky-c-text-passage {
            line-height: var(--sparky-line-height-md);
        }

        .sparky-c-text-link {
            line-height: size(2.25);
        }
    }

    &__icon {
        width: size(2.5);
    }
}
