@import '@petsmart-ui/sparky/dist/scss/functions';
@import '@petsmart-ui/sparky/dist/scss/breakpoints';

.legacy-mini-cart__link {
    display: block;
    position: relative;

    @media screen and (max-width: $sparky-bp-lg) {
        margin: 0 0 0 size(3);
    }

    @media screen and (min-width: $sparky-bp-lg) {
        margin: 15px 0 0 calc(var(--sparky-size-base-unit) * 2);
    }
}

.legacy-mini-cart__icon {
    display: inline-block;
    color: var(--sparky-color-neutral-black);
}

.legacy-mini-cart__notification {
    background-clip: padding-box !important;
    background-color: #ce2028 !important;
    position: absolute;
    font-family: var(--legacy-header-font-family) !important;
    top: -10px;
    left: 19px;
}
