@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.campaign-cards-info {
    width: 100%;
    margin-bottom: size(4);
    text-align: center;
    padding: 0;

    .sparky-c-image {
        width: 104px;
        margin: auto;
        object-fit: cover;
        border-radius: var(--sparky-border-radius-xl);
        overflow: hidden;
    }

    &__content {
        padding: size(3) size(2);
    }

    &__header {
        display: grid;
        gap: size(0.5);
        align-content: center;

        .sparky-c-text-link {
            justify-self: center;
        }
    }

    &__cards {
        display: grid;
        gap: size(2);
        margin-top: size(2);
        grid-template-columns: repeat(2, 1fr);
        align-items: start;
    }

    &__card {
        display: grid;
        justify-content: center;
        text-decoration: none;
        color: unset;
        gap: size(1);
    }

    &__card-title {
        font-size: var(--sparky-font-size-16);
        line-height: var(--sparky-line-height-xxxxl);
        font-weight: var(--sparky-font-weight-bold);
        margin-bottom: size(1);
    }

    @media (min-width: $sparky-bp-md) {
        .sparky-c-image {
            width: 142px;
        }

        .sparky-c-heading {
            font-size: var(--sparky-font-size-32);
            line-height: var(--sparky-line-height-md);
        }

        &__content {
            padding: size(3) size(4);
        }

        &__cards {
            grid-template-columns: repeat(4, 1fr);
            gap: size(3);
        }
    }

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);
        padding-inline: size(4);

        .sparky-c-image {
            width: 180px;
        }

        .sparky-c-heading {
            font-size: var(--sparky-font-size-40);
            line-height: var(--sparky-line-height-xs);
        }

        &__content {
            padding: size(4);
            border-radius: var(--sparky-border-radius-xl);
            overflow: hidden;
        }

        &__card {
            gap: size(2);
        }

        &__cards {
            gap: size(4);
        }
    }
}
