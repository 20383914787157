@import '~common-next/styles/functions';

.legacy-loggedin {
    height: 100%;
    padding-top: to-rem(20px);

    &__button {
        color: var(--sparky-color-neutral-800);
        display: inline-block;
        text-align: left;
        padding-top: to-rem(10px);

        .sparky-c-text-link {
            color: var(--sparky-color-neutral-800);

            &:hover {
                color: inherit;
                text-decoration: underline;
            }
        }

        span {
            text-transform: capitalize;
            font-family: Lato, sans-serif;
            font-weight: var(--sparky-font-weight-bold);
            font-size: var(--sparky-font-size-18);
        }
    }
}

.points {
    color: var(--sparky-color-neutral-800);
    background-color: var(--legacy-header-color-light-blue);
    background-repeat: no-repeat;
    background-image: url('https://images.petsmartassets.com/is/image/PetSmart/bones-bg'),
        url('https://images.petsmartassets.com/is/image/PetSmart/loyalty%2Dicon?$PGN75x50$');
    background-position:
        to-rem(-10px) to-rem(-12px),
        to-rem(30px) center;
    background-size:
        auto 120%,
        auto 50%;
    font-weight: 600;
    font-size: to-rem(14px);
    padding: to-rem(8px) to-rem(32px) to-rem(5px);
    text-align: center;

    .loyalty-account-menu-enabled & {
        color: var(--sparky-color-neutral-white);
        background-color: var(--legacy-header-color-loyal-red);
        background-image: url('https://s7d2.scene7.com/is/image/PetSmart/treats-dropdown-2[12]');
        background-position: 0 0;
        background-size: inherit;
    }

    .account {
        font-size: to-rem(14px);
        color: var(--sparky-color-neutral-800);
        position: absolute;
        top: to-rem(10px);
        right: to-rem(15px);
        text-decoration: underline;
        line-height: to-rem(17px);
        width: to-rem(64px);
        text-align: right;

        .loyalty-account-menu-enabled & {
            color: var(--sparky-color-neutral-white);
        }
    }

    .point-count {
        font-size: to-rem(40px);
        line-height: to-rem(57px);
    }
}

.points-link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        -webkit-text-decoration-color: var(--legacy-header-color-loyal-blue);
        text-decoration-color: var(--legacy-header-color-loyal-blue);
    }
}

.dropdown-accent {
    border: to-rem(12px) solid var(--legacy-header-color-light-blue);
    border-right-color: transparent;
    border-bottom-color: transparent;
    box-shadow: to-rem(-3px) to-rem(-3px) to-rem(4px) rgb(0 0 0 / 5%);
    position: absolute;
    left: 50%;
    top: to-rem(65px);
    transform: rotate(45deg) skew(-10deg, -10deg);
    z-index: 2000;
    display: none;

    .loyalty-account-menu-enabled & {
        border: to-rem(12px) solid var(--legacy-header-color-loyal-red);
    }
}

.legacy-loggedin__submenu {
    display: none;
    font-family: var(--legacy-header-font-family);
    position: relative;
    z-index: 1000;
    overflow: hidden;
    background-color: var(--sparky-color-neutral-white);
    border-radius: 0.4rem;
    box-shadow: 0 0 to-rem(16px) rgb(0 0 0 / 5%);
    top: to-rem(20px);
    width: to-rem(310px);
    left: to-rem(10px);

    .sparky-l-grid__item {
        border-bottom: to-rem(1px) solid
            var(--legacy-header-myaccount-dropdown-border-color);

        &:nth-child(odd) {
            border-right: to-rem(1px) solid
                var(--legacy-header-myaccount-dropdown-border-color);
        }

        a {
            display: block;
            font-weight: var(--legacy-header-font-weight-medium-bold);
            font-size: to-rem(15px);
            padding: to-rem(15px) 0;
            color: var(--sparky-color-neutral-800);
            font-family: var(--legacy-header-font-family);
            outline: none;

            &:focus,
            &:hover {
                text-decoration: underline;
                -webkit-text-decoration-color: var(
                    --legacy-header-color-loyal-blue
                );
                text-decoration-color: var(--legacy-header-color-loyal-blue);
            }
        }
    }

    .logout-link {
        background: var(--legacy-header-color-loyal-blue);
        color: var(--sparky-color-neutral-white);
        padding: to-rem(15px) to-rem(10px);
        text-align: center;
        transition: all 0.3s ease;
        border: 0;
        font-family: var(--legacy-header-font-family);
        font-weight: 600;
        display: block;
        width: 100%;
        background-color: var(--sparky-color-brand-blue-500);

        &:hover,
        &:visited,
        &:focus {
            color: var(--sparky-color-neutral-white);
            background-color: var(--sparky-color-brand-blue-600);
            outline: none;
            text-decoration: underline;
            -webkit-text-decoration-color: var(
                --legacy-header-color-loyal-blue
            );
            text-decoration-color: var(--legacy-header-color-loyal-blue);
        }
    }
}
