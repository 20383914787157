@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.storytelling-hero-card-4-up {
    margin-bottom: size(4);

    @media (min-width: $sparky-bp-xl) {
        margin-bottom: size(8);
    }

    .storytelling-card-up {
        text-decoration: none;

        .sparky-c-card__header {
            .sparky-c-image {
                border-radius: var(--sparky-border-radius-xl);
            }
        }

        &__hero {
            border-radius: var(--sparky-border-radius-xl);

            .sparky-c-heading {
                font-size: var(--sparky-font-size-32) !important;

                @media (min-width: $sparky-bp-xl) {
                    font-size: var(--sparky-font-size-40) !important;
                }
            }
        }

        &__hero-tile {
            border-radius: var(--sparky-border-radius-xl);
            position: relative;

            .sparky-c-card__header {
                margin-bottom: size(0);

                .sparky-c-image {
                    border-radius: var(--sparky-border-radius-xl);
                }
            }

            .sparky-c-card__body {
                position: absolute;
                padding: size(2);
                color: var(--sparky-color-neutral-white);

                .sparky-c-text-passage {
                    font-size: var(--sparky-font-size-18);

                    @media (min-width: $sparky-bp-xl) {
                        font-size: var(--sparky-font-size-20);
                    }
                }

                .sparky-c-heading {
                    font-size: size(6) !important;
                    line-height: var(--sparky-line-height-base) !important;

                    @media (min-width: $sparky-bp-xl) {
                        font-size: size(7) !important;
                    }
                }
            }
        }
    }
}
