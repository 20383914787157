@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.card-up {
    padding: size(3) size(2);
    margin: 0;

    .sparky-l-grid {
        margin-bottom: 0;
    }

    .sparky-c-section__title-after {
        &:not(&--below-description) {
            text-align: right;
            margin-left: size(3);
        }

        &--below-description {
            margin-top: size(0.5);
        }
    }

    .sparky-l-grid__item {
        padding: 0 0 size(3);

        &:last-child {
            padding: 0;
        }
    }

    .promo-card {
        img {
            border-radius: var(--sparky-border-radius-lg);
        }
    }

    &.info {
        padding-top: size(8);

        &.info--no-header {
            padding-top: size(4);
        }
    }

    &[data-variant='2up-offer'] {
        .sparky-l-grid--2up {
            display: flex;
        }

        .sparky-c-section__title {
            margin-right: size(3);

            &-after {
                margin-left: 0 !important;
                text-align: right !important;

                @media all and (min-width: $sparky-bp-md) {
                    text-align: left !important;
                }
            }
        }
    }

    &[data-variant='3up'] {
        .sparky-l-grid__item {
            width: 100%;
        }
    }

    &[data-variant='4stack'] {
        max-width: calc(
            var(--sparky-l-max-width) + var(--sparky-size-base-unit) * 6
        );
        margin: 0 auto;
        padding: size(4) size(2) 0;

        .sparky-l-grid__item {
            width: 50%;
            padding: 0 size(0.5) size(2);
            margin-top: 0;

            &:nth-child(odd) {
                padding-left: 0;
            }

            &:nth-child(even) {
                padding-right: 0;
            }
        }

        .sparky-c-heading,
        .sparky-c-text-passage {
            text-align: center;
        }

        .sparky-l-grid {
            flex-direction: row;
            margin: 0;
        }

        .sparky-c-card {
            background-color: unset;
        }

        .promo-card {
            img {
                width: 100%;
                object-fit: cover;
                aspect-ratio: 1;
            }
        }

        @media all and (min-width: $sparky-bp-sm-2) {
            padding: size(2) size(2) 0;

            .sparky-l-grid {
                align-items: baseline;
                margin: 0 size(1);
            }

            .sparky-l-grid__item {
                width: 25%;
                padding: size(2) size(1);
                margin-top: 0;

                &:nth-child(odd),
                &:nth-child(even) {
                    padding-right: size(1);
                    padding-left: size(1);
                }
            }
        }

        @media all and (min-width: $sparky-bp-xl) {
            padding: size(4) size(2);

            .sparky-l-grid {
                margin: 0;
            }

            .sparky-l-grid__item {
                width: 50%;
                padding: 0 size(2);
                margin: 0;

                &:nth-child(odd),
                &:nth-child(even) {
                    padding-right: size(2);
                    padding-left: size(2);
                }
            }
        }
    }

    @media all and (min-width: $sparky-bp-xl) {
        .sparky-c-section__title-after {
            text-align: inherit;
        }
    }

    @media all and (min-width: $sparky-bp-md) {
        padding: size(3) size(4);

        .sparky-l-grid__item {
            padding: size(1.5);

            &:last-child {
                padding: size(1.5);
            }
        }

        &[data-variant='3up'] {
            .sparky-l-grid__item {
                width: 50%;
            }
        }
    }

    @media all and (min-width: $sparky-bp-lg) {
        padding: size(4);
        border-radius: var(--sparky-border-radius-xl);
        overflow: hidden;
    }

    @media all and (min-width: $sparky-bp-xl) {
        .sparky-l-grid {
            margin: 0;
            flex-wrap: nowrap;
        }

        .sparky-l-grid__item {
            padding: 0;
            margin: calc(var(--sparky-size-base-unit) * 2);
            margin-bottom: 0;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
                padding: 0;
            }
        }

        .promo-card {
            img {
                border-radius: var(--sparky-border-radius-xl);
            }
        }

        &[data-variant='3up'] {
            .sparky-l-grid__item {
                width: 33.33%;
            }
        }

        &[data-variant='4up'] {
            .promo-card {
                img {
                    border-radius: var(--sparky-border-radius-lg);
                }
            }
        }
    }

    &--restyled {
        .sparky-c-section {
            &__title {
                margin-right: 0;
            }

            &__description {
                margin-top: size(0.5);
            }
        }

        .sparky-c-text-link {
            font-size: var(--sparky-font-size-14);
        }

        .sparky-c-heading--title {
            text-align: center;
            font-size: var(--sparky-font-size-32) !important;

            @media (min-width: $sparky-bp-lg) {
                font-size: var(--sparky-font-size-40) !important;
            }
        }
    }
}

.card-up-container {
    width: 100%;
    margin-bottom: size(4);
    padding: 0;

    @media (min-width: $sparky-bp-lg) {
        margin-bottom: size(8);
        padding-inline: size(4);
    }

    &--2up-offer {
        width: 100%;
    }

    &--4stack {
        max-width: none;
        padding: 0;
        overflow: hidden;

        &.title {
            .card-up {
                padding: size(2) size(2) 0;

                @media all and (min-width: $sparky-bp-xl) {
                    padding: size(4) size(2);
                }
            }

            .sparky-c-section__header {
                display: block;

                @media all and (min-width: $sparky-bp-sm-2) {
                    margin: 0 size(2);
                }

                @media all and (min-width: $sparky-bp-xl) {
                    margin: 0 size(2) size(4);
                }
            }
        }
    }
}
