@import '@petsmart-ui/sparky/dist/scss/breakpoints';
@import '@petsmart-ui/sparky/dist/scss/functions';

.campaign-hero-fwidth {
    display: grid;
    align-items: start;
    text-decoration: none;

    &__body {
        color: white;
        grid-area: 1 / 1;
        justify-items: start;
        padding: size(3) size(2);
        overflow: hidden;
    }

    &__heading {
        font-size: 48px;
        line-height: 56px;
        font-weight: 700;
        margin-bottom: size(2);

        div {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__text {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: size(4);
    }

    .sparky-c-picture {
        grid-area: 1 / 1;
        height: 100%;
    }

    .sparky-c-image {
        height: 100%;
        object-fit: cover;
    }

    &__grey {
        .campaign-hero-fwidth__body {
            color: #131313;
        }
    }

    &__red {
        .campaign-hero-fwidth__heading {
            color: #131313;
        }

        .campaign-hero-fwidth__text {
            color: #dd2834;
        }
    }

    @media (min-width: $sparky-bp-md) {
        align-items: center;

        .sparky-c-image {
            height: 400px;
        }

        &__body {
            box-sizing: content-box;
            max-width: 343px;
            padding: 0 size(4);
        }
    }

    @media (min-width: $sparky-bp-lg) {
        &__body {
            max-width: 424px;
            padding: 0 size(8);
        }

        &__heading {
            font-size: var(--sparky-font-size-56);
            line-height: 64px;
        }

        &__text {
            font-size: var(--sparky-font-size-20);
            line-height: var(--sparky-line-height-xxl);
        }
    }
}
